import React from "react";
import { Tab, TabView } from "../components/common/Tab.view";
import { ExpertStudioContentProps } from "../expert-studio/ExpertStudio.page";
import { CreateEventComponent } from "./CreateEvent.Component";
import { ManageEventComponent } from "./ManageEvent.component";

interface ExpertStudioEventContentProps extends ExpertStudioContentProps {}

export function ExpertStudioEventContent(props: ExpertStudioEventContentProps) {
  let tabs: Tab[] = [
    {
      title: "Manage Event",
      content: <ManageEventComponent user={props.user.basicDetails} />,
      uniqueKey: "manage",
      isActive: true,
    },
    {
      title: "Create Event",
      content: <CreateEventComponent user={props.user.basicDetails} />,
      uniqueKey: "create",
      isActive: true,
    },
  ];

  return (
    <div className="ExpertStudioEventContent">
      <h3>
        <i className="fas fa-calendar-alt"></i> Events/Webinars
      </h3>
      <TabView tabs={tabs} selectedTab={tabs[0]} />
    </div>
  );
}
