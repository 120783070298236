import React, { useEffect, useState } from "react";
import { BookingList } from "../../../booking/Booking.List";
import { Booking } from "../../../booking/Booking.model";
import { Loader } from "../../../components/common/Loader.component";
import { Center } from "../../../components/common/layout/Center.component";
import {
  OfferingFetchContext,
  OfferingTypeName,
  OfferingVideoSession,
} from "../../../offerings/Offering";
import { OfferingRegistrationComponent } from "../../../offerings/Offering.Registration.Component";
import { OfferingAPI } from "../../../offerings/Offering.api";
import { OfferingV2PopUpCard } from "../../../offerings/OfferingV2.PopUp.Card";
import { PageResponse } from "../../../paging/Paging.Model";
import { ContentItemDataOfferingVideoSession } from "../../Content.model";
import { ContentItemViewProps } from "../ContentItem.model";

export function ContentItemViewOfferingVideoSession(
  props: ContentItemViewProps
) {
  let data: ContentItemDataOfferingVideoSession = props.data;

  let [offering, setOffering] = useState<OfferingVideoSession>();
  let [menteeBookings, setMenteeBookings] = useState<PageResponse<Booking>>();
  let [offeringLoading, setOfferingLoading] = useState<boolean>();
  let [menteeBookingsLoading, setMenteeBookingsLoading] = useState<boolean>();

  function fetchOffering() {
    if (data && data.offeringId !== undefined) {
      setOfferingLoading(true);
      OfferingAPI.get(
        OfferingTypeName.VIDEO_SESSION,
        data.offeringId,
        OfferingFetchContext.PUBLIC
      )
        .executeV2()
        .then((offering) => {
          setOffering(offering as OfferingVideoSession);
        })
        .finally(() => {
          setOfferingLoading(false);
        });
    }
  }

  useEffect(() => {
    fetchOffering();
  }, []);

  return (
    <div className="ContentItemViewOfferingVideoSession">
      {offeringLoading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!offeringLoading && offering && props.user && (
        <OfferingV2PopUpCard
          className="offering-grid-card"
          offering={offering}
          loggedInUser={props.user ? { basicDetails: props.user } : undefined}
          registrationNode={
            <OfferingRegistrationComponent
              offering={offering as OfferingVideoSession}
              user={props.user}
            />
          }
        />
      )}
      {offering && props.user && (
        <div className="mt-3">
          Past Bookings:
          <div className="">
            <BookingList
              engineIds={[offering.commonDetails.postedBy.id]}
              bogieIds={[props.user.id]}
              offeringIds={[offering.id]}
              emptyBookingsComponent={<>No bookings found</>}
            />
          </div>
        </div>
      )}
    </div>
  );
}
