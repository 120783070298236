import React, { useEffect, useState } from "react";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { ReactMatch } from "../../model/ReactMatchObj";
import { PageProps } from "../../model/views/PageProps";
import { RelatedExpertsComponent } from "../../promotion/RelatedExperts.Component";
import { ViewsManager } from "../../reactions/Views.Manager";
import { ReviewsComponent } from "../../user/Reviews.component";
import { UserBasicDetails } from "../../user/User";
import { UserAPI } from "../../user/UserAPI";
import { UserProfileSidebar } from "../../user/UserProfile.Sidebar";
import { EngineSummaryCard } from "../../user/card/EngineSummary.Card";
import {
  IOfferingV2,
  OfferingFetchContext,
  OfferingTypeName,
} from "../Offering";
import { OfferingAPI } from "../Offering.api";
import { OfferingDetailPageDefaultLayout } from "../layouts/Offering.DetailPage.Default.Layout";
import { OfferingDetailPageLayoutTypeId } from "../layouts/Offering.DetailPage.Layout.Model";
import { OfferingDetailPageRightStickyLayout } from "../layouts/Offering.DetailPage.RightSticky.Layout";
import { OferingDetailPageContentHeader } from "./Ofering.DetailPage.ContentHeader";
import { OfferingDetailPageMainContentComponent } from "./Offering.DetailPageMainContent.Component";
import { OfferingRegistrationSectionComponent } from "./Offering.RegistrationSection.component";

interface OfferingPagePathParams {
  offeringType: OfferingTypeName;
  offeringId: string;
}

interface OfferingsPageContentProps extends PageProps {
  match: ReactMatch<OfferingPagePathParams>;
}

export function OfferingDetailPageContent(props: OfferingsPageContentProps) {
  const [offering, setOffering] = useState<IOfferingV2>();
  const [engine, setEngine] = useState<UserBasicDetails>();

  useEffect(() => {
    OfferingAPI.get(
      props.match.params.offeringType,
      props.match.params.offeringId,
      OfferingFetchContext.PUBLIC
    )
      .executeV2()
      .then((offering) => {
        setOffering(offering);
        UserAPI.fetchBasicUserDetails(
          offering.commonDetails.postedBy.id,
          true
        ).then((user) => {
          setEngine(user);
        });
      });

    ViewsManager.log({
      targetType: AttachmentTargetType.OFFERING,
      targetId: props.match.params.offeringId,
    });
  }, []);
  let Layout =
    offering?.commonDetails.layout?.id ==
    OfferingDetailPageLayoutTypeId.RIGHT_STICKY
      ? OfferingDetailPageRightStickyLayout
      : OfferingDetailPageDefaultLayout;
  return (
    <div className="OfferingDetailPageContent main-container bg-main-body h-100 pb-0">
      {offering && (
        <Layout
          headerContent={
            <OferingDetailPageContentHeader
              offering={offering}
              user={props.user}
            />
          }
          mainContent={
            <OfferingDetailPageMainContentComponent
              offering={offering}
              engine={engine}
            />
          }
          registrationContent={
            <OfferingRegistrationSectionComponent
              offering={offering}
              user={props.user?.basicDetails}
            />
          }
          sideBarTopContent={
            <>
              {engine && (
                <>
                  <UserProfileSidebar
                    userId={offering.commonDetails.postedBy.id}
                    user={engine}
                  />
                  {offering?.commonDetails.layout?.id !=
                    OfferingDetailPageLayoutTypeId.RIGHT_STICKY &&
                    engine.engineSummary && (
                      <EngineSummaryCard
                        es={engine.engineSummary}
                        className="mt-3"
                      />
                    )}
                </>
              )}
            </>
          }
          reviewContent={
            <>
              <div className="mt-4">
                <ReviewsComponent userId={offering.commonDetails.postedBy.id} />
              </div>

              {engine && (
                <RelatedExpertsComponent
                  context={{
                    topics: engine.skills,
                    excludedItemIds: [engine.id],
                  }}
                  horizontalLayout={true}
                  horizontalSpan={{ xs: 24, xl: 12 }}
                  className="mb-4 shadow-sm card card-body border border-1 rounded-4 bg-transparent bg-highlight mt-4"
                  header={<div className="text-muted mb-2">More experts:</div>}
                />
              )}
            </>
          }
        />
      )}
    </div>
  );
}
