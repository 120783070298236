import { Tooltip } from "antd";
import React from "react";

export function PendingApprovalBadge() {
  return (
    <Tooltip
      title={
        "Until approved, it is not displayed to other users. We keep reviewing and approving them."
      }
    >
      <span className="text-danger small">(Pending Approval)</span>
    </Tooltip>
  );
}
