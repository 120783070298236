import { message } from "antd";
import React, { useEffect, useState } from "react";
import TimeAgo from "timeago-react";
import { ApiLinkedButton } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { TimestampDurationView } from "../components/common/TimestampView";
import { Center } from "../components/common/layout/Center.component";
import { UserHelpers } from "../helpers/Helpers";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { UserBasicDetails } from "../user/User";
import { EventApi } from "./Event.Api";
import { EventV2 } from "./Event.Model";

export interface ManageEventComponentProps {
  user: UserBasicDetails;
}

export function ManageEventComponent(props: ManageEventComponentProps) {
  let [events, setEvents] = useState<EventV2[]>([]);
  let [loading, setLoading] = useState<boolean>(false);

  function fetchEvents(): Promise<void> {
    setLoading(true);
    return EventApi.getEvents(props.user.id, FetchContext.EDIT, true).then(
      (events: EventV2[]) => {
        setEvents(events);
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    fetchEvents();
  }, []);
  return (
    <div className="ManageEventComponent">
      <div className="row">
        <div className="col mt-1">
          <div className="row">
            {loading && (
              <>
                <Center>
                  <Loader />
                </Center>
              </>
            )}

            {!loading && (
              <EventsList
                events={events}
                user={props.user}
                onDeleteSuccess={fetchEvents}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

interface EventsListProps {
  events: EventV2[];
  user: UserBasicDetails;
  onDeleteSuccess: () => Promise<void>;
}

function EventsList(props: EventsListProps) {
  return (
    <div>
      {props.events.length == 0 && "No Events found. Please create some"}
      <div className="row">
        <div className="col">
          <div className="list-group">
            {props.events &&
              props.events.map((event, index) => (
                <EventsListItem
                  event={event}
                  key={event.id}
                  user={props.user}
                  onDeleteSuccess={props.onDeleteSuccess}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

interface EventsListItemProps {
  event: EventV2;
  user: UserBasicDetails;
  onDeleteSuccess: () => Promise<void>;
}

function EventsListItem(props: EventsListItemProps) {
  let event = props.event;
  return (
    <div className="row py-2" key={event.id}>
      <div className="card shadow">
        <div className="card-body">
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">
              {event.basicDetails.title ?? "Untitled Event"}
            </h5>
            <div className="event-data-desktop">
              <EventListItemOptions
                event={event}
                onDeleteSuccess={props.onDeleteSuccess}
              />
            </div>
          </div>
          {event.basicDetails?.duration && (
            <div className="w-100 mt-1">
              <b>Event's time: </b>

              <mark>
                <TimestampDurationView
                  startTimestamp={event.basicDetails?.duration.fromMillis}
                  endTimestamp={event.basicDetails?.duration.toMillis}
                />
              </mark>
            </div>
          )}

          <div className="d-flex w-100 justify-content-between">
            <div>
              <small className="text-muted">
                Created by: {UserHelpers.getFullName(props.user)}
              </small>
              {!event.basicDetails.isPublished && (
                <span className="badge bg-danger ml-1">Draft</span>
              )}
              {event.basicDetails.isPublished &&
                !event.basicDetails.completedAt && (
                  <span className="badge bg-info ml-1">Published</span>
                )}

              {event.basicDetails.completedAt && (
                <span className="badge bg-success ml-1">Completed</span>
              )}
            </div>
          </div>
          <div className="d-flex w-100 justify-content-between">
            <div>
              <small className="float-right">
                {event.basicDetails.createdAt ? (
                  <TimeAgo datetime={event.basicDetails.createdAt} />
                ) : null}
              </small>
            </div>
          </div>
          <div className="event-data-mobile mt-2">
            <EventListItemOptions
              event={event}
              onDeleteSuccess={props.onDeleteSuccess}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

interface EventListItemOptionsProps {
  event: EventV2;
  onDeleteSuccess: () => Promise<void>;
}

function EventListItemOptions(props: EventListItemOptionsProps) {
  let event = props.event;
  return (
    <>
      {" "}
      {event.basicDetails.isPublished && (
        <>
          <a
            href={event.landingUrl}
            className="ml-1 btn btn-light"
            role="button"
            target="_blank"
          >
            <i className="fa fa-eye" aria-hidden="true"></i> Preview
          </a>

          <a
            href={event.dashboardUrl}
            className="ml-1 btn btn-light"
            role="button"
            target="_blank"
          >
            <i className="fa-solid fa-table-columns"></i> Dashboard
          </a>
        </>
      )}
      {event.canUserEdit && (
        <a href={event.editUrl} className="ml-1 btn btn-light" role="button">
          <i className="fas fa-edit"></i> Edit
        </a>
      )}
      {event.canDelete && (
        <>
          <span className="ml-1">
            <ApiLinkedButton
              api={EventApi.deleteEvent(event.id)}
              buttonProps={{
                text: {
                  normal: (
                    <>
                      <i className="far fa-trash-alt mr-1"></i>Delete
                    </>
                  ),
                  submitting: <>Deleting</>,
                },
                type: "light",
              }}
              callback={() => {
                message.success(`Event '${event.basicDetails.title}' deleted`);
                return props.onDeleteSuccess();
              }}
              showConfirmation={true}
              uniqueKey={"event" + event.id + "-delete-" + crypto.randomUUID()}
            />
          </span>
        </>
      )}
    </>
  );
}
