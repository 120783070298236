import React, { useRef } from "react";
import ReactMde, { Command, TextApi } from "react-mde";
import "react-mde/lib/styles/css/react-mde-all.css";
import { AssetLocation } from "../../../model/Picture";
import { FileUploadComponent } from "../../../upload/FileUpload.component";
import { UserBasicDetails } from "../../../user/User";
import { EBButton } from "../EBButton";
import { EBModal } from "../EBModal";
import { MarkdownViewV1 } from "../MarkdownView";
import "./Markdown.input.css";

interface MarkdownInputProps {
  id: string;
  value?: string;
  onChange?: (newValue: string) => void;
  minEditorHeight?: number;
  user?: UserBasicDetails;
}

export function MarkdownV2Input(props: MarkdownInputProps) {
  const currentTextApi = useRef<TextApi>();
  const [selectedTab, setSelectedTab] = React.useState<"write" | "preview">(
    "write"
  );

  const imageCommand: Command = {
    execute: (opts) => {
      currentTextApi.current = opts.textApi;
      toggleImageUploadModal();
    },
  };

  function toggleImageUploadModal() {
    document.getElementById(imageInputModalToggleButtonId)?.click();
  }

  function imagePicked(image: AssetLocation): Promise<void> {
    currentTextApi.current?.replaceSelection("![](" + image.data + ")");
    toggleImageUploadModal();
    return Promise.resolve();
  }

  let imageInputModalId =
    props.id.replaceAll(".", "-") + "-md-image-input-modal";
  let imageInputModalToggleButtonId = imageInputModalId + "-toggle-button";

  return (
    <div className="MarkdownInput">
      {props.user && (
        <>
          <EBModal
            modalSize="sm"
            BodyComponent={
              <>
                <FileUploadComponent
                  onImageUpload={imagePicked}
                  api={"/api/user/{userId}/file/upload/image".replaceAll(
                    "{userId}",
                    props.user.id
                  )}
                />
              </>
            }
            id={imageInputModalId}
          />
          <EBButton
            classes={["d-none"]}
            otherProps={{
              "data-bs-toggle": "modal",
              "data-bs-target": "#" + imageInputModalId,
            }}
            text={{ normal: "a" }}
            id={imageInputModalToggleButtonId}
          />
        </>
      )}

      <ReactMde
        commands={
          props.user
            ? {
                image: imageCommand,
              }
            : undefined
        }
        value={props.value}
        onChange={props.onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        minEditorHeight={props.minEditorHeight}
        generateMarkdownPreview={(markdown) =>
          Promise.resolve(<MarkdownViewV1>{markdown}</MarkdownViewV1>)
        }
        childProps={{
          writeButton: {
            tabIndex: -1,
          },
        }}
      />
    </div>
  );
}
