import { message } from "antd";
import React, { useState } from "react";
import { BookDl } from "../Global.Names";
import { Mode } from "../components/common/Form/model/mode.model";
import { PriceView } from "../components/common/Price.view";
import { FormikHiddenInput } from "../components/common/input/FormikHiddentInput";
import { CouponApplyResponse } from "../coupon/Coupon.model";
import { FormDataComponent } from "../form/Form.Component";
import { FormTemplateHelper } from "../form/template-builder/Form.Template.Helper";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { Price } from "../model/Price";
import { UserBasicDetails } from "../user/User";
import {
  IOfferingV2,
  OfferingTextQuery,
  OfferingVideoSession,
} from "./Offering";
import { OfferingBookNowButton } from "./Offering.BookNow.Button";
import { OfferingAPI } from "./Offering.api";
import { OfferingRegisterSectionDefaultLayout } from "./layouts/Offering.RegisterSection.Default.Layout";
import { OfferingTextQueryPreBookingFormElements } from "./text-query/OfferingTextQueryPreBookingFormElements";
import { OfferingVideoSessionPreBookingFormElements } from "./video-session/OfferingVideoSessionPreBookingFormElements";

export interface OfferingRegistrationComponentProps {
  offering: IOfferingV2;
  user: UserBasicDetails;
}

export function OfferingRegistrationComponent(
  props: OfferingRegistrationComponentProps
) {
  let [selectedPrice, setSelectedPrice] = useState<Price>(
    props.offering.commonDetails.price
  );
  let [couponSuccess, setCouponSuccess] = useState<CouponApplyResponse>();
  let [mandatoryOptionsSelected, setMandatoryOptionsSelected] =
    useState<boolean>();

  function onPriceFinalization(price: Price) {
    setSelectedPrice(price);
    setCouponSuccess(undefined);
  }

  function onMandatoryOptionsSelection(selected: boolean) {
    setMandatoryOptionsSelected(selected);
  }

  function onCouponApply(bookingData: any): Promise<void> {
    return OfferingAPI.applyCoupon(props.offering.commonDetails.target)
      .executeV2(bookingData)
      .then((couponApplyResponse) => {
        message.success(couponApplyResponse.message);
        setCouponSuccess(couponApplyResponse);
      })
      .catch((error) => {
        setCouponSuccess(undefined);
        return error;
      });
  }

  return (
    <>
      <div
        className="OfferingRegistrationComponent"
        id="OfferingRegistrationComponent"
      >
        {/* <div className="px-2 event-data-desktop">
          <h5>Register Here</h5>
          <b>
            {couponSuccess && (
              <>
                <s>
                  <PriceView
                    price={props.offering.commonDetails.price}
                    showFree={true}
                  />
                </s>{" "}
                <PriceView price={couponSuccess.newPrice} showFree={true} />
              </>
            )}
            {!couponSuccess && (
              <PriceView
                price={props.offering.commonDetails.price}
                showFree={true}
              />
            )}
          </b>
        </div> */}
        <OfferingBookNowButton
          Layout={OfferingRegisterSectionDefaultLayout}
          priceVariationFormElement={
            <>
              {props.offering.commonDetails.target.targetType ==
                AttachmentTargetType.OFFERING_TEXT_QUERY && (
                <OfferingTextQueryPreBookingFormElements
                  offering={props.offering as OfferingTextQuery}
                  onPriceFinalization={onPriceFinalization}
                  onMandatoryOptionsSelection={onMandatoryOptionsSelection}
                  user={props.user}
                />
              )}

              {props.offering.commonDetails.target.targetType ==
                AttachmentTargetType.OFFERING_VIDEO_SESSION && (
                <OfferingVideoSessionPreBookingFormElements
                  offering={props.offering as OfferingVideoSession}
                  onPriceFinalization={onPriceFinalization}
                  onMandatoryOptionsSelection={onMandatoryOptionsSelection}
                  user={props.user}
                />
              )}

              {props.offering.commonDetails.target.targetType !=
                AttachmentTargetType.OFFERING_VIDEO_SESSION &&
                props.offering.commonDetails.target.targetType !=
                  AttachmentTargetType.OFFERING_TEXT_QUERY && (
                  <FormikHiddenInput
                    value={props.offering.commonDetails.options[0].id}
                    name={"optionsData.id"}
                  />
                )}
            </>
          }
          preBookingFormElement={
            FormTemplateHelper.hasItems(
              props.offering.commonDetails.preBookingFormTemplate
            ) && (
              <FormDataComponent
                template={props.offering.commonDetails.preBookingFormTemplate!}
                mode={Mode.EDIT}
                user={props.user}
                name="preBookingFormData"
              />
            )
          }
          orderApi={OfferingAPI.order(props.offering.commonDetails.target)}
          onCouponApply={onCouponApply}
          showRegisterButton={true}
          text={
            selectedPrice && (
              <>
                {BookDl.SENTENCE_START} @{" "}
                {couponSuccess && (
                  <>
                    <s>
                      <PriceView price={selectedPrice} />
                    </s>{" "}
                    <PriceView price={couponSuccess?.newPrice} />
                  </>
                )}
                {!couponSuccess && <PriceView price={selectedPrice} />}
              </>
            )
          }
        />
      </div>
    </>
  );
}
