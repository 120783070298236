import { message } from "antd";
import React, { useEffect, useState } from "react";
import { EBButtonV2 } from "../../components/common/EBButtonV2";
import { Loader } from "../../components/common/Loader.component";
import { TextInputV3 } from "../../components/common/input/TextV3.Input";
import { Center } from "../../components/common/layout/Center.component";
import { ContentInput } from "../../content/Content.Input";
import { Content } from "../../content/Content.model";
import { ApiErrorHelper } from "../../helpers/ApiError.helper";
import { SaveIcon } from "../../icon/Save.Icon";
import { AttachmentTargetType } from "../../model/AttachmentTargetType";
import { UserBasicDetails } from "../../user/User";
import { OfferingPath } from "../Offering";
import { PathStep } from "./Offering.Path.Model";
import { OfferingPathStepAPI } from "./Offering.PathStep.api";

interface OfferingsPathStepEditProps {
  user: UserBasicDetails;
  offeringId: string;
  offering: OfferingPath;
  inputStep: PathStep;
  onStepUpdate: (step: PathStep) => void;
  onChange: () => Promise<void>;
  saveDirtyChanges?: boolean;
}

export function OfferingsPathStepEdit(props: OfferingsPathStepEditProps) {
  let [loading, setLoading] = useState<boolean>();
  let [step, setStep] = useState<PathStep>();
  let [dl, setDl] = useState<string>();

  useEffect(() => {
    setLoading(true);
    OfferingPathStepAPI.get(
      props.user.id,
      props.offeringId,
      props.inputStep.id + ""
    )
      .then((step) => {
        if (!step.content) {
          step.content = { items: [] } as Content;
        }
        setDl(step.dl);
        setStep(step);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.inputStep.id]);

  function onDlChange(newDl: string) {
    if (step) {
      step.dl = newDl;
      setDl(newDl);
      props.onChange();
    }
  }

  let [saving, setSaving] = useState<boolean>(false);
  function save(): Promise<void> {
    if (!step) {
      return Promise.resolve();
    }
    setSaving(true);

    return OfferingPathStepAPI.update(
      props.user.id,
      props.offeringId,
      props.inputStep.id + "",
      step
    )
      .then((result) => {
        props.onStepUpdate(result);
        message.success("Step Updated");
      })
      .catch((error) => {
        let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
        message.error(displayError);
      })
      .finally(() => {
        setSaving(false);
      });
  }

  return (
    <span className="OfferingsPathStepEdit">
      {loading && (
        <Center className="p-4">
          <Loader />
        </Center>
      )}
      {!loading && step && (
        <ContentInput
          postedBy={props.offering.commonDetails.postedBy}
          onChange={props.onChange}
          user={props.user}
          target={{
            targetType: AttachmentTargetType.OFFERING_PATH,
            targetId: props.offeringId,
          }}
          topNode={
            <div className="row">
              <div className="col">
                <TextInputV3
                  className="h2 w-100 border-0 text-dark-gray"
                  placeholder="Untitled Step..."
                  helpText={
                    !step.dl
                      ? "Please edit and input the title you want to give"
                      : undefined
                  }
                  onChange={onDlChange}
                  onPressEnter={() => {
                    save();
                  }}
                  value={dl}
                />

                {/* <EBTextInputV2 name="dl" required /> */}
              </div>
              <div className="col-auto">
                <EBButtonV2
                  className="btn-sm"
                  content={{
                    normal: (
                      <>
                        <SaveIcon /> Save Step
                      </>
                    ),
                  }}
                  disabled={saving}
                  loading={saving}
                  onClick={save}
                />
              </div>
            </div>
          }
          content={step.content!}
          onTriggerSave={save}
        />
      )}
    </span>
  );
}
