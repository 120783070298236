import { Collapse, Flex } from "antd";
import React from "react";
import { reloadWindowPromise } from "../components/common/reloadCallback";
import { RemoteList, RemoteListItemProps } from "../list/Remote.List";
import { User } from "../user/User";
import { UserLink } from "../user/User.link";
import { FormAPI } from "./Form.api";
import { FormStatusBadge } from "./FormStatus.Badge";
import { FormWrapperComponent } from "./FormWrapper.Component";
import { FormResponse, FormStatus } from "./model/form.model";

interface FormListProps {
  onFetch: () => Promise<FormResponse[]>;
  user: User;
}
export function FormList(props: FormListProps) {
  return (
    <div className="FormList">
      <RemoteList
        onFetch={props.onFetch}
        ItemComponent={FormListItem}
        user={props.user}
        emptyListContent="Not found"
      />
    </div>
  );
}

interface FormListItemProps extends RemoteListItemProps {
  showFormIds?: boolean;
}
function FormListItem(props: FormListItemProps) {
  let item = props.item as FormResponse;
  return (
    <div className="FormListItem mb-2">
      <Collapse
        defaultActiveKey={props.length == 1 ? [item.id] : []}
        items={[
          {
            key: item.id,
            label: (
              <div className="d-flex">
                <div className="flex-grow-1 pr-2 hstack gap-2">
                  <UserLink user={item.submittedBy} imgSize={30} />
                </div>
                <div className="">
                  <Flex align="center" gap="middle">
                    <div className="h5 mb-0">Id: {item.id}</div>

                    <FormStatusBadge
                      status={
                        item.submittedAt
                          ? FormStatus.SUBMITTED
                          : FormStatus.DRAFT
                      }
                    />
                  </Flex>
                </div>
              </div>
            ),
            children: (
              <FormWrapperComponent
                initialFetchApi={FormAPI.getFormApi(item.id)}
                submitProps={{
                  title: "Submit",
                  callback: reloadWindowPromise,
                }}
                disableEdit={true}
                user={props.user.basicDetails}
                hideStatusBadge={true}
              />
            ),
          },
        ]}
      />
    </div>
  );
}
