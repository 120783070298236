import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { useEffect } from "react";
import { CompaniesBadgeList } from "../badge/Companies.BadgeList";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { EditTitle } from "../components/common/Edit.title";
import { MarkdownViewV1 } from "../components/common/MarkdownView";
import { UrlHelpers } from "../helpers/Helpers";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { HTMLProps } from "../model/HTMLProps";
import { ViewsManager } from "../reactions/Views.Manager";
import { TopicBadge } from "../topic/Topic.Badge";
import { UserBasicDetails } from "../user/User";
import { AnswerSubmissionStatusBadge } from "./AnswerSubmissionStatus.Badge";
import { DifficultyBadge } from "./Difficulty.badge";
import { Question } from "./Question.Model";
import { AnswerSubmission } from "./QuestionAnswerSubmission.model";
import { QuestionTypeInfoComponent } from "./QuestionTypeInfo.Component";

interface QuestionViewProps extends HTMLProps {
  question: Question;
  user?: UserBasicDetails;
  hideEditBtn?: boolean;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionView(props: QuestionViewProps) {
  let question = props.question;
  return (
    <div className={`QuestionView card card-body ${props.className}`}>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h1 className="h4">{question.title}</h1>
        </div>
        {question.canUserEdit && !props.hideEditBtn && (
          <div className="justify-content-end">
            <Link
              href={UrlHelpers.getEditQuestionDetailsUrl(
                question.slug,
                question.id
              )}
              className="float-right btn btn-light btn-sm"
            >
              <EditTitle text="Edit" />
            </Link>
          </div>
        )}
      </div>
      <div className="hstack gap-4">
        <DifficultyBadge difficulty={question.difficulty} />

        {question.answerStatus && (
          <AnswerSubmissionStatusBadge status={question.answerStatus} />
        )}

        {question.canUserEdit && !question.approvedAt && (
          <PendingApprovalBadge />
        )}
      </div>
      <div className="mt-2">
        <QuestionContentView
          question={question}
          user={props.user}
          onSubmitAnswer={props.onSubmitAnswer}
        />
      </div>
      <div className="mt-2">
        <QuestionBagdesView question={question} />
      </div>
    </div>
  );
}

interface QuestionContentViewProps extends HTMLProps {
  question: Question;
  user?: UserBasicDetails;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionContentView(props: QuestionContentViewProps) {
  useEffect(() => {
    ViewsManager.log({
      targetType: AttachmentTargetType.QUESTION,
      targetId: props.question.id + "",
    });
  }, []);
  return (
    <span className={`QuestionContentView ${props.className}`}>
      <MarkdownViewV1>{props.question.statement}</MarkdownViewV1>
      <QuestionTypeInfoComponent
        typeInfo={props.question.typeInfo}
        question={props.question}
        user={props.user}
        onSubmitAnswer={props.onSubmitAnswer}
      />
    </span>
  );
}

interface QuestionBagdesViewProps {
  question: Question;
}

export function QuestionBagdesView(props: QuestionBagdesViewProps) {
  return (
    <span className="QuestionBagdesView">
      {props.question.topics && (
        <span className="mob-pt-1">
          {props.question.topics.map((topic, index) => (
            <TopicBadge topic={topic} key={index} />
          ))}
        </span>
      )}

      {props.question.companies && (
        <span className="mob-pt-1">
          <CompaniesBadgeList companies={props.question.companies} />
        </span>
      )}
    </span>
  );
}

interface QuestionAccordionViewProps {
  question: Question;
  user?: UserBasicDetails;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
  expanded?: boolean;
}

export function QuestionAccordionView(props: QuestionAccordionViewProps) {
  let question = props.question;
  return (
    <span className="QuestionAccordionView">
      <Collapse
        defaultActiveKey={props.expanded ? [question.id] : []}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: question.id,
            label: (
              <div className="d-flex">
                <div className="flex-grow-1">
                  <div className="h5">{question.title}</div>
                  <div>
                    <QuestionBagdesView question={question} />
                  </div>
                </div>

                <div className="justify-content-end pl-4 pr-2 hstack gap-2">
                  {question.answerStatus && (
                    <AnswerSubmissionStatusBadge
                      status={question.answerStatus}
                    />
                  )}
                  <DifficultyBadge difficulty={question.difficulty} />
                </div>
              </div>
            ),
            children: (
              <QuestionContentView
                question={question}
                user={props.user}
                onSubmitAnswer={props.onSubmitAnswer}
              />
            ),
          },
        ]}
      />
    </span>
  );
}
