import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { PageResponse } from "../paging/Paging.Model";
import { QuestionConstants } from "./Question.Constants";
import {
  Question,
  QuestionCreateUpdateRequest,
  QuestionSearchResponse,
} from "./Question.Model";
import { AnswerSubmission } from "./QuestionAnswerSubmission.model";

export class QuestionsAPI {
  public static async fetchQuestionDetails(
    questionId: number
  ): Promise<Question> {
    let api = apiLinkConstants.QUESTIONS_FETCH_SINGLE_BY_ID.replace(
      "{questionId}",
      questionId + ""
    );
    return new Promise<Question>((resolve, reject) => {
      axios
        .get(api)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static search(): Api<SearchRequest, PageResponse<Question>> {
    return new Api<SearchRequest, PageResponse<Question>>(
      apiLinkConstants.QUESTIONS_SEARCH,
      ApiMethod.POST
    );
  }

  public static async getAllQuestions(): Promise<QuestionSearchResponse> {
    return this.search()
      .executeV2({
        searchTokens: [],
        pageParams: { size: 10000, page: 0 },
      })
      .then((response: PageResponse<Question>) => {
        return { questions: response.content };
      });
  }

  public static async addQuestion(
    question: QuestionCreateUpdateRequest
  ): Promise<Question> {
    return new Promise<Question>((resolve, reject) => {
      axios
        .post(apiLinkConstants.QUESTIONS_ADD, question)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static async updateQuestion(
    question: QuestionCreateUpdateRequest
  ): Promise<Question> {
    let api = apiLinkConstants.QUESTIONS_UPDATE_SINGLE_BY_ID.replace(
      "{questionId}",
      question.id + ""
    );
    return new Promise<Question>((resolve, reject) => {
      axios
        .put(api, question)
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static submitAnswer(
    questionId: string,
    userId: string,
    inputValue: AnswerSubmission
  ): Promise<AnswerSubmission> {
    return new Api<AnswerSubmission, AnswerSubmission>(
      QuestionConstants.QUESTION_ANSWER_SUBMISSION_ADD.replaceAll(
        "{questionId}",
        questionId
      ).replaceAll("{userId}", userId),
      ApiMethod.POST
    ).execute(inputValue);
  }
}
