import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { CallerContext } from "../components/common/CallerContext";
import { EBButton } from "../components/common/EBButton";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { FormikQuestionDifficultySelect } from "../components/common/input/FormikQuestionDifficultySelect";
import { MarkdownInput } from "../components/common/input/Markdown.input";
import { ButtonText } from "../model/views/Button.props";
import { Topic } from "../topic/Topic.model";
import { TopicAPI } from "../topic/TopicAPI";
import { TopicSelector } from "../topic/TopicSelector.component";
import { UserBasicDetails } from "../user/User";
import { Question, QuestionDifficulty } from "./Question.Model";
import { QuestionTypeInfoInput } from "./QuestionTypeInfo.Input";
import { QuestionsAPI } from "./QuestionsAPI";

interface AddOrUpdateQuestionFormFormProps {
  user: UserBasicDetails;
  callback: (question: Question, callerContext: CallerContext) => Promise<void>;
  callerContext: CallerContext;
  addButtonText?: ButtonText;
  question?: Question;

  // This property will ask this component whether to clear the question in the form post submitting.
  // Example use case is when showing question picker modal during interview experience creation.
  clearQuestionPostSubmit?: boolean;
}

const EMPTY_QUESTION_ID = -1;

export function AddOrUpdateQuestionForm(
  props: AddOrUpdateQuestionFormFormProps
) {
  let [allTopics, setAllTopics] = useState<Topic[]>();
  useEffect(() => {
    TopicAPI.getListingTopics().then((topics) => {
      setAllTopics(topics);
    });
  }, []);

  const alertManager = useAlert();
  function getEmptyQuestion(): Question {
    return {
      id: EMPTY_QUESTION_ID,
      title: "",
      statement: "",
      isAnon: false,
      difficulty: QuestionDifficulty.EASY,
    };
  }

  return (
    <div className="AddOrUpdateQuestionForm">
      <Formik
        initialValues={props.question ?? ({} as Question)}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let api: Promise<Question>;
          if (values.id == undefined || values.id === EMPTY_QUESTION_ID) {
            api = QuestionsAPI.addQuestion(values);
          } else {
            api = QuestionsAPI.updateQuestion(values);
          }
          api
            .then(function (question: Question) {
              if (props.clearQuestionPostSubmit) {
                actions.resetForm({
                  values: getEmptyQuestion(),
                });
              }
              props.callback(question, props.callerContext).then(() => {
                actions.setSubmitting(false);
              });
            })
            .catch(function (error) {
              alertManager.error(
                "Error occurred! Make sure all required fields are filled."
              );
              actions.setSubmitting(false);
            });
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={values.id} />
            <div className="row">
              <div className="col-md-6">
                <EBTextInput
                  label="Title"
                  name="title"
                  placeholder="Add title for the question"
                  helpText=""
                  required
                />
              </div>
              <div className="col-md-6">
                {allTopics && (
                  <>
                    <TopicSelector
                      label="Topics:"
                      topics={allTopics}
                      name={"topics"}
                      isMulti={true}
                      required={true}
                    />
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <Label label="Statement:" required={true} />
                  <MarkdownInput
                    name={"statement"}
                    required={true}
                    user={props.user}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col mb-3">
                <FormInputLabel label="Question Type: " required={true} />
                <QuestionTypeInfoInput name={"typeInfo"} required={true} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormikQuestionDifficultySelect
                  isMulti={false}
                  label="Difficulty? "
                  name="difficulty"
                  required
                />
              </div>
            </div>

            <div className="row pt-2">
              <div className="col">
                <EBButton
                  classes={["btn btn-primary"]}
                  text={
                    props.addButtonText ?? {
                      normal: "Save",
                      submitting: "Saving ",
                    }
                  }
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                />
              </div>
              <div className="col text-end">
                <Field
                  type="checkbox"
                  id="isAnon"
                  className="custom-checkbox custom-control-input"
                  name="isAnon"
                />
                <label className="custom-control-label" htmlFor="isAnon">
                  Anonymous?
                </label>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
