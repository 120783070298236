import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { Skill } from "../model/Skill";
import { EditOfferingOptionsComponent } from "../offerings/EditOfferingOptions.component";
import { OfferingTypeName, SessionType } from "../offerings/Offering";
import { OfferingInputSection } from "../offerings/Offering.Input.Section";
import { OfferingsEditComponent } from "../offerings/Offerings.Edit.Component";
import { SessionTypeSelector } from "../offerings/SessionTyoeSelector.component";
import { OfferingVideoSessionAPI } from "../offerings/video-session/Offering.VideoSession.Api";
import { SkillAPI } from "../skills/SkillAPI";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface OfferingsVideoSessionEditExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function OfferingsVideoSessionEditExpertStudioContent(
  props: OfferingsVideoSessionEditExpertStudioContentProps
) {
  let [sessionTypes, setSessionTypes] = useState<SessionType[]>([]);
  let [skills, setSkills] = useState<Skill[]>([]);

  useEffect(() => {
    OfferingVideoSessionAPI.getVideoSessionTypes().then((sessionTypes) => {
      setSessionTypes(sessionTypes);
    });

    SkillAPI.fetchAll().then((skills) => {
      setSkills(skills);
    });
  }, []);
  let alert = useAlert();
  return (
    <div className="OfferingsVideoSessionEditExpertStudioContent">
      <h5>Edit Video Session Offerings:</h5>

      {sessionTypes && skills && (
        <OfferingsEditComponent
          user={props.user}
          offeringType={OfferingTypeName.VIDEO_SESSION}
          offeringTargetType={AttachmentTargetType.OFFERING_VIDEO_SESSION}
          titleSideNode={
            <OfferingInputSection
              title={<Label label={"Type:"} required={true} />}
              content={
                <SessionTypeSelector
                  all={sessionTypes}
                  name={`sessionType`}
                  required={true}
                  fieldTitle=""
                />
              }
            />
          }
          postTitleNode={
            <OfferingInputSection
              title={<Label label={"Options:"} required={true} />}
              content={
                <EditOfferingOptionsComponent
                  offeringSessionTypeFieldName={"sessionType"}
                  name={`commonDetails.options`}
                  skills={skills}
                  sessionTypes={sessionTypes}
                  user={props.user.basicDetails}
                />
              }
            />
          }
        />
      )}
    </div>
  );
}
