import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { BookingApi } from "../booking/Booking.Api";
import { Booking } from "../booking/Booking.model";
import { Loader } from "../components/common/Loader.component";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { OfferingsPathStepsViewPageContent } from "../offerings/path/Offerings.Path.Steps.View.PageContent";
import { UserLink } from "../user/User.link";

interface BookingPathPageContentProps extends PageProps {
  match: ReactMatch<BookingPathPageContentPathParams>;
}
interface BookingPathPageContentPathParams {
  bookingId: string;
}

export function BookingPathPageContent(props: BookingPathPageContentProps) {
  let [booking, setBooking] = useState<Booking>();
  useEffect(function () {
    BookingApi.get(props.match.params.bookingId)
      .executeV2()
      .then((booking) => {
        setBooking(booking);
      });
  }, []);

  return (
    <div className="BookingPathPageContent h-100">
      {booking && props.user && booking.bcd.bogie && booking.bcd.offeringId ? (
        <div className="d-flex flex-column h-100">
          <Row className="border-bottom w-100 py-3 px-3" gutter={[12, 12]}>
            <Col flex="auto">
              <h1 className="h4 m-0 p-0">Path: {booking.bcd.dl}</h1>
            </Col>
            <Col className="">
              Expert:{" "}
              <UserLink
                user={booking.bcd.engine}
                imgSize={25}
                imgRight={true}
              />
            </Col>
          </Row>
          <div className="flex-grow-1">
            <OfferingsPathStepsViewPageContent
              user={booking.bcd.bogie}
              offeringId={booking.bcd.offeringId}
              postedById={booking.bcd.engineId}
              booking={booking}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
