import React from "react";
import { ExperienceHelpers } from "../helpers/Helpers";
import { Company } from "../model/Company";
import { BadgeDesignLightYellow } from "./Badge.Design";
import { BadgeList } from "./Badge.List";
import { CompanyBadge } from "./Company.Badge";

interface CompaniesBadgeList {
  companies: Company[];
  viewLimit?: number;
}

export function CompaniesBadgeList(props: CompaniesBadgeList) {
  let companies = ExperienceHelpers.getDedupedCompanies(props.companies);

  return (
    <>
      {companies && (
        <BadgeList
          size={companies.length}
          render={(index) => {
            return (
              companies &&
              companies[index] && <CompanyBadge company={companies[index]} />
            );
          }}
          viewLimit={props.viewLimit}
          ViewMoreDesign={BadgeDesignLightYellow}
        />
      )}
    </>
  );
}
