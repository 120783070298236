import React from "react";
import {
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { DurationTimestampInput } from "../components/common/input/DurationTimestamp.input";
import { MarkdownInput } from "../components/common/input/Markdown.input";
import { AttachmentInput } from "../components/common/upload/AttachmentInput";
import { VenueFormikInput } from "../location/Venue.FormikInput";
import { UserBasicDetails } from "../user/User";

export interface EventCreateOrUpdateDetailsBasicStepProps {
  user: UserBasicDetails;
}
export function EventCreateOrUpdateDetailsBasicStep(
  props: EventCreateOrUpdateDetailsBasicStepProps
) {
  return (
    <div className="EventCreateOrUpdateDetailsBasicStep">
      <div className="row">
        <EBTextInput
          label="Event Title"
          name={`title`}
          placeholder="Add basic information of the event"
          helpText=""
          required
        />
      </div>
      <div className="row">
        <FormInputLabel
          label="Event Descritption"
          required="true"
        ></FormInputLabel>
        <MarkdownInput name={`description`} user={props.user} />
      </div>
      <div className="row">
        <VenueFormikInput name={`venue`} required={true} />
      </div>
      <div className="row">
        <div className="col">
          <FormInputLabel label="Banner Image" />
          <div className="border p-2">
            <AttachmentInput
              name={`bannerImage`}
              user={props.user}
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <FormInputLabel
            label="Event Duration"
            required="true"
          ></FormInputLabel>
          <DurationTimestampInput name={`duration`} />
        </div>
      </div>
    </div>
  );
}
