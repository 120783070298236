import { message } from "antd";
import { Form, Formik, useField } from "formik";
import React from "react";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { MarkdownViewV1 } from "../components/common/MarkdownView";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { ApiErrorHelper } from "../helpers/ApiError.helper";
import { ArrayHelper } from "../helpers/Array.Helper";
import { UserBasicDetails } from "../user/User";
import {
  Question,
  QuestionTypeDataMcq,
  QuestionTypeDataMcqType,
} from "./Question.Model";
import {
  AnswerSubmission,
  AnswerSubmissionStatus,
  QuestionTypeAnswerSubmissionDataMcq,
} from "./QuestionAnswerSubmission.model";
import { QuestionsAPI } from "./QuestionsAPI";

interface QuestionTypeMcqComponentProps {
  mcqData: QuestionTypeDataMcq;
  question: Question;
  user?: UserBasicDetails;
  onSubmitAnswer?: (answer: AnswerSubmission) => void;
}

export function QuestionTypeMcqComponent(props: QuestionTypeMcqComponentProps) {
  return (
    <div className="QuestionTypeMcqComponent">
      <Formik
        initialValues={{} as AnswerSubmission}
        onSubmit={(values) => {
          if (props.user) {
            return QuestionsAPI.submitAnswer(
              props.question.id + "",
              props.user.id,
              values
            )
              .then((submissionResponse) => {
                if (submissionResponse.status === AnswerSubmissionStatus.AC) {
                  message.success("Correct answer");
                }

                if (submissionResponse.status === AnswerSubmissionStatus.WA) {
                  message.error("Wrong Answer");
                }
                if (props.onSubmitAnswer) {
                  props.onSubmitAnswer(submissionResponse);
                }
                return Promise.resolve();
              })
              .catch((error) => {
                let displayErrorMessage =
                  ApiErrorHelper.getDisplayErrorMessage(error);
                message.error(displayErrorMessage);
              });
          } else {
            return Promise.resolve();
          }
        }}
      >
        {(formProps) => (
          <Form>
            <div className="mt-2">
              <QuestionTypeMcqAnswerSubmissionDataInput
                mcqData={props.mcqData}
                name={"answerSubmissionData"}
              />
            </div>
            {props.onSubmitAnswer && (
              <>
                <EBButtonV2
                  type="submit"
                  className="btn-sm mb-3"
                  content={{ normal: "Submit" }}
                  loading={formProps.isSubmitting}
                />
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

interface QuestionTypeMcqAnswerSubmissionDataInputProps
  extends FormikInputParams {
  mcqData: QuestionTypeDataMcq;
}
export function QuestionTypeMcqAnswerSubmissionDataInput(
  props: QuestionTypeMcqAnswerSubmissionDataInputProps
) {
  let [field] = useField(props);
  // let [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  let mcqSubmissionData: QuestionTypeAnswerSubmissionDataMcq | undefined =
    field.value;
  let selectedOptions: string[] = mcqSubmissionData?.selectedOptions ?? [];

  function updateField() {
    field.onChange({
      target: {
        name: props.name,
        value: { selectedOptions: selectedOptions },
      },
    });
  }

  function optionSelected(optionId: string) {
    if (!selectedOptions.includes(optionId)) {
      if (props.mcqData.type === QuestionTypeDataMcqType.SINGLE) {
        selectedOptions = [optionId];
      } else {
        selectedOptions.push(optionId);
      }

      updateField();
    }
  }

  function optionUnSelected(optionId: string) {
    if (selectedOptions.includes(optionId)) {
      ArrayHelper.remove(selectedOptions, optionId);
      updateField();
    }
  }

  return (
    <div className="QuestionTypeMcqAnswerSubmissionDataInput">
      {props.mcqData.options.map((option, index) => (
        <div key={index} className="row">
          <div className="col-auto pt-1 pr-2">
            <input
              type={`${
                props.mcqData.type === QuestionTypeDataMcqType.SINGLE
                  ? "radio"
                  : "checkbox"
              }`}
              checked={selectedOptions.includes(option.id)}
              value={option.id}
              style={{ height: "18px", width: "18px" }}
              onChange={(event) => {
                if (event.target.checked) {
                  optionSelected(option.id);
                } else {
                  optionUnSelected(option.id);
                }
              }}
              name={`${props.name}.selectedOptions`}
            />
          </div>
          <div className="col pl-0">
            <MarkdownViewV1>{option.text}</MarkdownViewV1>
          </div>
        </div>
      ))}
    </div>
  );
}
