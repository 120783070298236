import { Col, Row, message } from "antd";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { EBButton } from "../components/common/EBButton";
import { ApiLinkedButtonV3 } from "../components/common/Form/ApiLinked.button.V3";
import { Mode } from "../components/common/Form/model/mode.model";
import { apiLinkConstants } from "../constants";
import { ModeHelpers } from "../helpers/Mode.helper";
import { List, ListItemProps } from "../list/List";
import { ListItemPlainLayout } from "../list/ListItem.Layout";
import { UserBasicDetails } from "../user/User";
import { FormAPI } from "./Form.api";
import { FormStatusBadge } from "./FormStatus.Badge";
import { FormLineItemComponent } from "./line-items/FormLineItemComponent";
import { FormInputData, FormResponse, FormStatus } from "./model/form.model";
import {
  FormTemplateResponse,
  FormTemplateSection,
} from "./model/formTemplate.model";
import { FormTemplateHelper } from "./template-builder/Form.Template.Helper";

export interface FormSubmitProps {
  title?: string;
  callback: () => Promise<void>;
}

export interface FormComponentProps {
  form: FormResponse;
  mode?: Mode;
  submitProps?: FormSubmitProps;
  user: UserBasicDetails;
  disableEdit?: boolean;
  hideStatusBadge?: boolean;
  customerFormDl?: string;
  emptyFormDl?: React.ReactNode;
}

export function FormComponent(props: FormComponentProps) {
  let form = props.form;
  let [mode, setMode] = useState<Mode>(props.mode ?? Mode.VIEW);

  return (
    <div className="FormComponent">
      {form && (
        <>
          <Formik
            initialValues={form.formData}
            onSubmit={(values) => {
              return FormAPI.saveApi(form!.id)
                .executeV2(values)
                .then(() => {
                  setMode(Mode.VIEW);
                  message.success("Saved successfully");
                });
            }}
          >
            {(formProps) => {
              return (
                <Form>
                  <FormComponentHeader
                    className="mb-4"
                    setMode={setMode}
                    mode={mode}
                    form={form}
                    isSubmitting={formProps.isSubmitting}
                    submitProps={props.submitProps}
                    disableEdit={props.disableEdit}
                    hideStatusBadge={props.hideStatusBadge}
                    customerFormDl={props.customerFormDl}
                  />

                  {mode === Mode.EDIT ||
                  (formProps.values.sectionsData != undefined &&
                    Object.keys(formProps.values.sectionsData).length > 0) ? (
                    <FormDataComponent
                      template={form?.template}
                      formData={formProps.values}
                      mode={mode}
                      user={props.user}
                    />
                  ) : (
                    <>{props.emptyFormDl ?? <>Yet to be filled.</>}</>
                  )}
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </div>
  );
}

export interface FormDataComponentProps {
  template: FormTemplateResponse;
  formData?: FormInputData;
  mode: Mode;
  user: UserBasicDetails;
  disableEdit?: boolean;
  name?: string;
}

export function FormDataComponent(props: FormDataComponentProps) {
  let namePrefix = props.name && props.name.length ? `${props.name}.` : "";
  return (
    <>
      {FormTemplateHelper.hasItems(props.template) && (
        <List
          itemNode={(itemProps: ListItemProps) => {
            let section = itemProps.item as FormTemplateSection;
            return (
              <FormSectionComponent
                sectionTemplate={itemProps.item}
                sectionData={
                  props.formData?.sectionsData
                    ? props.formData.sectionsData[section.name]
                    : {}
                }
                mode={props.mode}
                name={`${namePrefix}sectionsData.${section.name}`}
                user={props.user}
                isLast={itemProps.index == itemProps.length - 1}
              />
            );
          }}
          onFetch={() => {
            return Promise.resolve(props.template.templateData?.sections);
          }}
          ItemLayoutComponent={ListItemPlainLayout}
        />
      )}
    </>
  );
}

interface FormComponentHeaderProps {
  isSubmitting: boolean;
  form?: FormResponse;
  mode: Mode;
  setMode: (mode: Mode) => void;
  submitProps?: FormSubmitProps;
  disableEdit?: boolean;

  className?: string;
  hideStatusBadge?: boolean;
  customerFormDl?: string;
}

function FormComponentHeader(props: FormComponentHeaderProps) {
  return (
    <div className={`FormComponentHeader ${props.className}`}>
      <Row gutter={[12, 12]} align="middle">
        <Col flex="auto">
          <Row gutter={[12, 2]} align="middle">
            <Col flex="">
              <span className="h4 m-0 p-0">
                {props.customerFormDl ??
                  ModeHelpers.getString(
                    props.mode,
                    props.form?.template.templateData?.displayTitle
                  )}
              </span>
            </Col>
            <Col>
              {!props.hideStatusBadge && props.mode == Mode.VIEW && (
                <FormStatusBadge
                  status={
                    props.form?.submittedAt
                      ? FormStatus.SUBMITTED
                      : FormStatus.DRAFT
                  }
                />
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <div className="hstack gap-2">
            {props.mode == Mode.EDIT && (
              <div className="hstack gap-2">
                <EBButton
                  type="button"
                  text={{
                    normal: "Cancel",
                    submitting: "Cancel",
                  }}
                  onClick={() => props.setMode(Mode.VIEW)}
                  classes={["btn btn-secondary"]}
                ></EBButton>
                <EBButton
                  type="submit"
                  text={{
                    normal: "Save Form",
                    submitting: "Saving",
                  }}
                  classes={["btn btn-primary"]}
                  loading={props.isSubmitting}
                ></EBButton>
              </div>
            )}
            {!props.disableEdit &&
              props.mode == Mode.VIEW &&
              props.form?.formMetadata.canEdit && (
                <>
                  <EBButton
                    type="button"
                    text={{
                      normal: (
                        <>
                          <i className="fas fa-edit"></i> Edit
                        </>
                      ),
                      submitting: "",
                    }}
                    onClick={() => props.setMode(Mode.EDIT)}
                    classes={["btn btn-primary"]}
                  ></EBButton>
                  {props.submitProps && (
                    <ApiLinkedButtonV3
                      apiFormBody={
                        <>
                          Are you sure?{" "}
                          <div className="mt-2 alert alert-warning">
                            This action is irreversible. Once submitted, you
                            will not be able to undo it.
                          </div>
                        </>
                      }
                      formModalSize="md"
                      api={
                        new Api(
                          apiLinkConstants.FORM_SUBMIT.replace(
                            "{formId}",
                            props.form?.id
                          ),
                          ApiMethod.POST
                        )
                      }
                      buttonProps={{
                        content: {
                          normal: props.submitProps.title ?? "Submit",
                          submitting: "Submitting...",
                        },
                      }}
                      uniqueKey={`form-submi-${props.form.id}`}
                      successCallback={props.submitProps.callback}
                    />
                  )}
                </>
              )}
          </div>
        </Col>
      </Row>
      {ModeHelpers.getString(
        props.mode,
        props.form?.template.templateData?.displayDescription
      )}
    </div>
  );
}

interface FormSectionComponentProps {
  sectionTemplate: FormTemplateSection;
  sectionData: any;
  mode: Mode;
  name: string;
  user: UserBasicDetails;
  isLast?: boolean;
}

export function FormSectionComponent(sectionProps: FormSectionComponentProps) {
  return (
    <div className="FormSectionComponent" key={`${sectionProps.name}`}>
      <h5 className="">
        {ModeHelpers.getString(
          sectionProps.mode,
          sectionProps.sectionTemplate.displayTitle
        )}
      </h5>
      <div className="card-text">
        {sectionProps.mode === Mode.EDIT || sectionProps.sectionData ? (
          <>
            {sectionProps.sectionTemplate.lineItems.map((lineItem, index) => (
              <FormLineItemComponent
                key={`${sectionProps.name}.${lineItem.name}`}
                name={`${sectionProps.name}.${lineItem.name}`}
                type={lineItem.type}
                required={lineItem.validations?.required ?? false}
                customValidationsData={lineItem.validations?.customValidations}
                templateData={lineItem.data}
                inputData={
                  sectionProps.sectionData
                    ? sectionProps.sectionData[lineItem.name]
                    : {}
                }
                mode={sectionProps.mode}
                user={sectionProps.user}
              />
            ))}
          </>
        ) : (
          <>Not found</>
        )}
      </div>
      {!sectionProps.isLast && <hr />}
    </div>
  );
}
