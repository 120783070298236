import React, { useEffect, useState } from "react";
import { Api } from "../components/common/ApiLinkedButton";
import { Loader } from "../components/common/Loader.component";
import { Center } from "../components/common/layout/Center.component";
import { UserBasicDetails } from "../user/User";
import { FormComponent, FormSubmitProps } from "./Form.Component";
import { FormResponse } from "./model/form.model";

interface FormWrapperComponentProps {
  initialFetchApi: Api<void, FormResponse>;
  user: UserBasicDetails;

  submitProps?: FormSubmitProps;
  disableEdit?: boolean;
  className?: string;
  hideStatusBadge?: boolean;
  customerFormDl?: string;
  emptyFormDl?: React.ReactNode;
}

export function FormWrapperComponent(props: FormWrapperComponentProps) {
  let [form, setForm] = useState<FormResponse>();
  let [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setLoading(true);

    props.initialFetchApi.executeV2().then((formResp) => {
      setForm(formResp);
      setLoading(false);
    });
  }, []);
  return (
    <div className={`FormWrapperComponent ${props.className}`}>
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {form && (
        <FormComponent
          form={form}
          submitProps={props.submitProps}
          user={props.user}
          disableEdit={props.disableEdit}
          hideStatusBadge={props.hideStatusBadge}
          customerFormDl={props.customerFormDl}
          emptyFormDl={props.emptyFormDl}
        />
      )}
    </div>
  );
}
