import { FieldArray, useField } from "formik";
import React, { useEffect, useState } from "react";
import { AddTitle } from "../components/common/Add.title";
import { DeleteTitle } from "../components/common/Delete.title";
import { EditTitle } from "../components/common/Edit.title";
import { Mode } from "../components/common/Form/model/mode.model";
import {
  EBNumberInput,
  EBTextInput,
  FormInputLabel,
} from "../components/common/eb-ui-components";
import { DurationTimestampInput } from "../components/common/input/DurationTimestamp.input";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { EditPriceComponent } from "../components/common/price/EditPrice.component";
import { PriceHelper } from "../components/common/price/Price.Helper";
import { Currency } from "../model/Price";
import { UserBasicDetails } from "../user/User";
import { EventTicket } from "./Event.Model";

function defaultTicket(): EventTicket {
  return {
    isEnabled: true,
    price: {
      amount: 100000,
      currency: { id: "INR", title: "Indian Rupee (₹)" },
    },
    title: "Single Person",
    availableQuantity: 50,
  };
}

function emptyTicket(currency?: Currency): EventTicket {
  return {
    isEnabled: true,
    price: PriceHelper.getDefaultOfferingPrice(currency),
  };
}

export interface TicketListInputProps extends FormikInputParams {
  user: UserBasicDetails;
}

export function TicketListInput(props: TicketListInputProps) {
  let [field] = useField(props);
  let tickets: EventTicket[] | undefined = field.value;
  useEffect(() => {
    if (!field.value || field.value.length === 0) {
      field.onChange({
        target: {
          name: props.name,
          value: [defaultTicket()],
        },
      });
    }
  }, []);
  return (
    <div className="TicketListInput">
      <p>Please add atleast one ticket details for an event!!</p>
      <FieldArray name={props.name}>
        {({ insert, remove, push }: any) => (
          <div>
            {tickets?.map((ticket: EventTicket, index: number) => (
              <div className="card mt-2 pl-2">
                <TicketInput
                  ticket={ticket}
                  index={index}
                  length={tickets?.length}
                  onDelete={remove}
                  name={`${props.name}.${index}`}
                  required
                  user={props.user}
                />
              </div>
            ))}
            <div className="mt-2 hstack gap-2 float-right">
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => {
                  push(emptyTicket(props.user.currency));
                }}
              >
                <AddTitle text="Add Ticket" />
              </button>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}

interface TicketInputProps extends FormikInputParams {
  user: UserBasicDetails;

  ticket: EventTicket;
  index: number;
  length: number | undefined;
  onDelete: (index: number) => void;
}
function TicketInput(props: TicketInputProps) {
  let [mode, setMode] = useState<Mode>(props.ticket.id ? Mode.VIEW : Mode.EDIT);

  function toggleMode() {
    if (mode === Mode.VIEW) {
      setMode(Mode.EDIT);
    } else if (mode === Mode.EDIT) {
      setMode(Mode.VIEW);
    }
  }
  return (
    <div className="TicketInput">
      <div className="card-header row d-flex justify-content-between align-items-center">
        <div className="col">
          <span className={`${props.ticket.isEnabled ? "bold" : "text-muted"}`}>
            Tickets {props.index + 1} : {props.ticket.title}
          </span>
        </div>

        <div className="col-auto float-right hstack">
          <button type="button" className="btn btn-light" onClick={toggleMode}>
            <EditTitle />
          </button>
          <FormikSwitchInput name={`${props.name}.isEnabled`} label="" />
          <button
            className="btn secondary"
            type="button"
            onClick={() => props.onDelete(props.index)}
            disabled={!props.ticket.isEnabled}
          >
            <DeleteTitle />
          </button>
        </div>
      </div>
      {mode === Mode.EDIT && (
        <div className="card-body">
          <div className="row mt-2">
            <EBTextInput
              label="Title"
              name={`${props.name}.title`}
              placeholder="Add Title for the ticket"
              helpText="Early Bird, Single Person etc."
              required
              disabled={!props.ticket.isEnabled}
            />
          </div>
          <div className="row">
            <div className="col">
              <EditPriceComponent
                name={`${props.name}.price`}
                helpText="Price of the ticket"
                supportCustom={true}
                disabled={!props.ticket.isEnabled}
                defaultCurrency={props.user.currency}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <EBNumberInput
                label="Available Quantity"
                name={`${props.name}.availableQuantity`}
                placeholder="Add Quantity "
                helpText="(Number of tickets of this type you want to sell.)"
                required
                disabled={!props.ticket.isEnabled}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInputLabel
                label="Ticket Sale Duration"
                required="true"
              ></FormInputLabel>
              <DurationTimestampInput name={`${props.name}.saleDuration`} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
