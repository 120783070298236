import React from "react";
import { QuestionDifficulty } from "./Question.Model";

interface DifficultyLabelProps {
  difficulty: QuestionDifficulty;
}

export function DifficultyLabel(props: DifficultyLabelProps) {
  return (
    <>
      {props.difficulty === QuestionDifficulty.HARD && (
        <div className="text-danger">Hard</div>
      )}
      {props.difficulty === QuestionDifficulty.MEDIUM && (
        <div className="text-warning">Medium</div>
      )}
      {props.difficulty === QuestionDifficulty.EASY && (
        <div className="text-success">Easy</div>
      )}
    </>
  );
}
