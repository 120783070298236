import { useField } from "formik";
import React from "react";
import "react-mde/lib/styles/css/react-mde-all.css";
import { UserBasicDetails } from "../../../user/User";
import { FormikInputParams } from "./FormikInputParams.model";
import "./Markdown.input.css";
import { MarkdownV2Input } from "./MarkdownV2.Input";

interface MarkdownInputProps extends FormikInputParams {
  minEditorHeight?: number;
  user: UserBasicDetails;
}

export function MarkdownFormikInput(props: MarkdownInputProps) {
  const [field, meta] = useField(props);

  function onChange(newValue: string) {
    field.onChange({
      target: {
        name: props.name,
        value: newValue,
      },
    });
  }

  let id = props.name.replaceAll(".", "-");

  return (
    <div className="MarkdownFormikInput">
      <MarkdownV2Input
        id={id}
        value={field.value}
        onChange={onChange}
        {...props}
      />
    </div>
  );
}
