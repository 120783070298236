import React from "react";
import { Target } from "../model/target.model";
import { UserBasicDetails } from "../user/User";
import "./Content.Input.css";
import { Content, ContentItemTypeEnum } from "./Content.model";
import { ContentItemViewGroupCall } from "./items/view/ContentItemView.GroupCall";
import { ContentItemViewMarkdown } from "./items/view/ContentItemView.Markdown";
import { ContentItemViewOfferingVideoSession } from "./items/view/ContentItemView.OfferingVideoSession";
import { ContentItemViewQuestion } from "./items/view/ContentItemView.Question";
import { ContentItemViewVideoUpload } from "./items/view/ContentItemView.VideoUpload";
import { ContentItemViewYouTubeVideo } from "./items/view/ContentItemView.YouTubeVideo";

interface ContentViewProps {
  user: UserBasicDetails;
  topNode?: React.ReactNode;
  target: Target;
  content: Content;
}

export function ContentView(props: ContentViewProps) {
  let content: Content = props.content;

  return (
    <div className="ContentView">
      {props.topNode}
      {content &&
        content.items.map((item, index) => (
          <div key={index} className="mt-4">
            {item.type &&
              item.type.typeEnum === ContentItemTypeEnum.MARKDOWN && (
                <ContentItemViewMarkdown data={item.data} user={props.user} />
              )}

            {item.type &&
              item.type.typeEnum === ContentItemTypeEnum.YOUTUBE && (
                <ContentItemViewYouTubeVideo
                  data={item.data}
                  user={props.user}
                />
              )}
            {item.type &&
              item.type.typeEnum === ContentItemTypeEnum.VIDEO_UPLOAD && (
                <ContentItemViewVideoUpload
                  data={item.data}
                  user={props.user}
                />
              )}
            {item.type &&
              item.type.typeEnum === ContentItemTypeEnum.QUESTION && (
                <ContentItemViewQuestion data={item.data} user={props.user} />
              )}

            {item.type &&
              item.type.typeEnum ===
                ContentItemTypeEnum.OFFERING_VIDEO_SESSION && (
                <ContentItemViewOfferingVideoSession
                  data={item.data}
                  user={props.user}
                />
              )}

            {item.type &&
              item.type.typeEnum === ContentItemTypeEnum.GROUP_CALL && (
                <ContentItemViewGroupCall data={item.data} user={props.user} />
              )}
          </div>
        ))}
    </div>
  );
}
