import { apiLinkConstants, publicLinkConstants } from "../constants";
import { EventTicket } from "../events/Event.Model";
import { Company } from "../model/Company";
import {
  InterviewExperience,
  InterviewExperienceResponse,
} from "../model/InterviewExperience";
import { InterviewRound } from "../model/InterviewRound";
import { Picture } from "../model/Picture";
import { Price } from "../model/Price";
import { ReviewV1 } from "../model/Reviews";
import { Experience, User, UserBasicDetails } from "../user/User";
import { UserContributionsSummary } from "../user/UserContributions";

export class StringHelpers {
  public static capitalizeEachWordFirstCharacter(input: string): string {
    return input
      .toLowerCase()
      .split(" ")
      .map((s) => StringHelpers.capitalizeFirstLetter(s))
      .join(" ");
  }

  public static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

export class UserHelpers {
  private static DEFAULT_USER_NAME = "User";

  public static getUserFullName(user?: User): string {
    if (!user) {
      return "User";
    }
    return this.getFullName(user.basicDetails);
  }

  public static getFullName(user?: UserBasicDetails): string {
    if (!user) {
      return "enginEBogie User";
    }
    if (!user.firstName && !user.lastName) {
      return this.DEFAULT_USER_NAME;
    }
    var name = "";
    if (user.firstName) {
      name = user.firstName;
    }
    if (user.lastName) {
      name += " " + user.lastName;
    }

    return StringHelpers.capitalizeEachWordFirstCharacter(name.trim());
  }

  public static isExpert(user: UserBasicDetails): boolean {
    return user.isExpert ? user.isExpert : false;
  }

  public static getDisplayFirstName(user?: UserBasicDetails): string {
    if (!user?.firstName) {
      return this.DEFAULT_USER_NAME;
    }
    return StringHelpers.capitalizeEachWordFirstCharacter(
      user.firstName.toLowerCase()
    );
  }

  public static getProfilePicUrl(user?: UserBasicDetails): string {
    let pictureUrl = AssetHelpers.getAssetUrl(user?.dp);
    return pictureUrl ? pictureUrl : "/img/user-default.jpg";
  }

  public static getUserProfileUrl(user: UserBasicDetails) {
    if (user.username) {
      return publicLinkConstants.USER_PROFILE.replace(":userId", user.username);
    }
    return publicLinkConstants.USER_PROFILE.replace(":userId", user.id);
  }
}

export class ImageHelpers {
  public static validImage(imageData: string): boolean {
    if (imageData.match(/\.(jpg|jpeg|png|gif)$/)) {
      return true;
    } else {
      return false;
    }
  }
}
export class AssetHelpers {
  public static getAssetUrl(asset?: Picture): string | undefined {
    if (asset && asset.data && asset.locationType === "URL") {
      return asset.data;
    }

    return undefined;
  }
}

export class RatingsHelpers {
  public static getDisplayRating(ratingValue: number): string {
    return ratingValue.toFixed(1).replace(/\.0+$/, "");
  }
}

export enum UserRole {
  EXPERT,
  MENTEE,
  UNKNOWN,
}

export class ExpertSessionHelpers {
  public static getDisplayDuration(durationInSeconds: number) {
    let durationInMins = durationInSeconds / 60;
    if (durationInMins >= 60) {
      let durationInHrs = durationInMins / 60;
      if (durationInHrs === 1) {
        return durationInHrs + " hour";
      }
      return durationInHrs + " hours";
    }
    return durationInSeconds / 60 + " minutes";
  }
}

export class PriceHelpers {
  public static getDisplayPriceV2(price?: Price): string {
    if (!price) {
      return "";
    }
    if (price.amount == 0) {
      return "Free";
    }
    if (price.currency.id === "INR") {
      return "₹ " + price.amount / 100;
    }

    if (price.currency.id === "USD") {
      return "$ " + price.amount / 100;
    }

    return "";
  }
}

export class ApiUrlHelpers {
  public static getOrderForOrderId(orderId: string): string {
    return apiLinkConstants.GET_ORDER.replace("{orderId}", orderId);
  }

  public static getContributionsURLForUser(userId: string): string {
    return apiLinkConstants.USER_CONTRIBUTIONS_API.replace("{userId}", userId);
  }

  public static getContributionsSummartURLForUser(userId: string): string {
    return apiLinkConstants.SPECIFIC_USER_CONTRIBUTIONS_SUMMARY_API.replace(
      "{userId}",
      userId
    );
  }

  public static getAddCompanyInterviewLoopsURL(companyId: string): string {
    return apiLinkConstants.ADD_INTERVIEW_LOOP.replace(
      "{companyId}",
      companyId
    );
  }

  public static getCompanyInterviewLoopsURL(companyId: string): string {
    return apiLinkConstants.FETCH_ALL_INTERVIEW_LOOPS.replace(
      "{companyId}",
      companyId
    );
  }

  public static getSpecificInterviewLoopURL(
    companyId: string,
    interviewLoopId: string
  ): string {
    return apiLinkConstants.FETCH_INTERVIEW_LOOP.replace(
      "{companyId}",
      companyId
    ).replace("{interviewLoopId}", interviewLoopId);
  }

  public static getInterviewRoundAddURL(
    companyId: string,
    interviewLoopId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_ADD.replace(
      "{companyId}",
      companyId
    ).replace("{interviewLoopId}", interviewLoopId);
  }

  public static updateInterviewerURL(
    companyId: string,
    interviewLoopId: string,
    interviewRoundId: string
  ): string {
    return apiLinkConstants.UPDATE_INTERVIEWER.replace("{companyId}", companyId)
      .replace("{interviewLoopId}", interviewLoopId)
      .replace("{interviewRoundId}", interviewRoundId);
  }

  public static getInterviewRoundFetchAllURL(
    companyId: string,
    interviewLoopId: string
  ): string {
    return apiLinkConstants.INTERVIEW_LOOP_INTERVIEW_ROUND_FETCH_ALL.replace(
      "{companyId}",
      companyId
    ).replace("{interviewLoopId}", interviewLoopId);
  }

  public static getInterviewRoundFetchByIdURL(
    companyId: string,
    interviewLoopId: string,
    interviewRoundId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_FETCH_BY_ID.replace(
      "{companyId}",
      companyId
    )
      .replace("{interviewLoopId}", interviewLoopId)
      .replace("{interviewRoundId}", interviewRoundId);
  }

  public static interviewRoundUpdateGuideline(
    interviewRoundId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_UPDATE_GUIDELINE.replace(
      "{interviewRoundId}",
      interviewRoundId
    );
  }

  public static interviewRoundUpdateRecording(
    interviewRoundId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_UPDATE_RECORDING.replace(
      "{interviewRoundId}",
      interviewRoundId
    );
  }

  public static interviewRoundUpdateInterviewerFeedback(
    interviewRoundId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_UPDATE_INTERVIEWER_FEEDBACK.replace(
      "{interviewRoundId}",
      interviewRoundId
    );
  }

  public static interviewRoundUpdateAttachment(
    interviewRoundId: string
  ): string {
    return apiLinkConstants.INTERVIEW_ROUND_UPDATE_ATTACHMENT.replace(
      "{interviewRoundId}",
      interviewRoundId
    );
  }

  public static courseEventFetchById(courseId: number): string {
    return apiLinkConstants.COURSE_EVENT_FETCH_BY_ID.replace(
      "{courseId}",
      courseId.toString()
    );
  }

  public static masterclassEventFetchById(masterclassId: number): string {
    return apiLinkConstants.MASTERCLASS_EVENT_FETCH_BY_ID.replace(
      "{masterclassId}",
      masterclassId.toString()
    );
  }

  public static orderCheckout(orderId: string): string {
    return apiLinkConstants.ORDER_CHECKOUT.replace("{orderId}", orderId);
  }

  public static eventAttendeesFetchAll(eventId: string): string {
    return apiLinkConstants.EVENT_ATTENDEES_FETCH_ALL.replace(
      "{eventId}",
      eventId
    );
  }
}

export class UserContributionsHelpers {
  public static getMentoringDurationDisplayMsg(
    contributionSummary: UserContributionsSummary
  ) {
    let totalMentoringDurationInSeconds =
      contributionSummary.totalMentoringDurationInSeconds;
    if (totalMentoringDurationInSeconds < 600) {
      return totalMentoringDurationInSeconds + " seconds";
    }

    if (totalMentoringDurationInSeconds < 7200) {
      return Math.floor(totalMentoringDurationInSeconds / 60) + " minutes";
    }

    return Math.floor(totalMentoringDurationInSeconds / 3600) + " hours";
  }
}

export class UrlHelpers {
  public static userProfileUrl(user: User): string {
    return publicLinkConstants.USER_PROFILE.replace(
      ":userId",
      user.basicDetails.id
    );
  }

  public static getInterviewExperienceDetailsUrl(
    slug?: string,
    interviewExperienceId?: number,
    successMessage?: string
  ): string {
    if (!interviewExperienceId || !slug) {
      return "#";
    }

    let url = publicLinkConstants.INTERVIEW_EXPERIENCE_DETAILS.replace(
      ":interviewExperienceId",
      "" + interviewExperienceId
    ).replace(":slug", slug);

    if (!successMessage) {
      return url;
    }

    return url.concat("?" + "successMessage=" + successMessage);
  }

  public static getQuestionDetailsUrl(
    slug?: string,
    questionId?: number,
    successMessage?: String
  ): string {
    if (!questionId || !slug) {
      return "#";
    }

    let url = publicLinkConstants.QUESTION_DETAILS.replace(
      ":questionId",
      "" + questionId
    ).replace(":slug", slug);

    if (!successMessage) {
      return url;
    }

    return url.concat("?" + "successMessage=" + successMessage);
  }

  public static getPostDetailsUrl(
    url?: string,
    successMessage?: String
  ): string {
    if (successMessage && url) {
      return url.concat("?" + "successMessage=" + successMessage);
    } else {
      return "Url Not Found";
    }
  }

  public static getEditQuestionDetailsUrl(
    slug?: string,
    questionId?: number
  ): string {
    if (!questionId || !slug) {
      return "#";
    }

    return publicLinkConstants.QUESTION_EDIT.replace(
      ":questionId",
      "" + questionId
    ).replace(":slug", slug);
  }

  public static getEditInterviewExperienceUrl(
    slug?: string,
    interviewExperienceId?: number
  ): string {
    if (!interviewExperienceId || !slug) {
      return "#";
    }
    return publicLinkConstants.INTERVIEW_EXPERIENCE_EDIT.replace(
      ":slug",
      slug
    ).replace(":interviewExperienceId", "" + interviewExperienceId);
  }

  public static getInterviewLoopDetailsUrl(
    companyId: string,
    interviewLoopId: number
  ) {
    return publicLinkConstants.SPECIFIC_INTERVIEW_LOOP_PAGE.replace(
      ":interviewLoopId",
      "" + interviewLoopId
    ).replace(":companyId", companyId);
  }

  public static generateSlug(text: string): string {
    return text.toLowerCase().replaceAll(" ", "-");
  }
}

export class ExperienceHelpers {
  public static getDisplayStartDate(experience: Experience) {
    return experience.startDate;
  }

  public static getDisplayEndDate(experience: Experience) {
    if (!experience.endDate) {
      return "Present";
    }
    return experience.endDate;
  }

  public static getDedupedCompanies(companies?: Company[]): Company[] {
    if (!companies) {
      return [];
    }
    let result: Company[] = [];
    var processedCompanies: { [id: string]: boolean } = {};

    companies.forEach((company) => {
      if (!processedCompanies[company.id]) {
        result.push(company);
        processedCompanies[company.id] = true;
      }
    });
    return result;
  }

  public static getDedupedCompaniesFromExperiences(
    experiences: Experience[]
  ): Company[] {
    let result: Company[] = [];
    var processedCompanies: { [id: string]: boolean } = {};

    experiences.forEach((exp) => {
      if (!processedCompanies[exp.company.id]) {
        result.push(exp.company);
        processedCompanies[exp.company.id] = true;
      }
    });
    return result;
  }
}

export class InterviewExperienceHelpers {
  public static getCompanyName(
    interviewExperience: InterviewExperienceResponse
  ): string {
    if (interviewExperience.company) {
      return interviewExperience.company.dl;
    }
    return "";
  }

  public static postedByDisplayTitle(
    interviewExperience: InterviewExperience
  ): string {
    if (interviewExperience.isAnon || !interviewExperience.postedBy) {
      return "Anonymous";
    }
    return UserHelpers.getFullName(interviewExperience.postedBy);
  }
}

export class InterviewRoundHelpers {
  public static getDisplayStatus(interviewRound: InterviewRound) {
    if (interviewRound.cancelledAt) {
      return "Cancelled (at " + interviewRound.cancelledAt + ")";
    }

    if (interviewRound.completedAt) {
      return "Completed (at " + interviewRound.completedAt + ")";
    }

    if (interviewRound.scheduledAt) {
      return "Scheduled";
    }

    return "Created";
  }
}

export class EventTicketHelpers {
  public static isBookingAllowed(ticket: EventTicket) {
    return (
      ticket?.totalFreeQuantity != undefined &&
      ticket?.totalFreeQuantity > 0 &&
      ticket.saleDuration &&
      new Date(ticket.saleDuration?.fromMillis) <= new Date() &&
      new Date(ticket.saleDuration?.toMillis) >= new Date()
    );
  }

  public static availabilityStatus(ticket: EventTicket): string {
    if (
      ticket.saleDuration &&
      new Date(ticket.saleDuration?.fromMillis) > new Date()
    ) {
      return "Sale not yet started!";
    }

    if (
      ticket.saleDuration &&
      new Date(ticket.saleDuration?.toMillis) < new Date()
    ) {
      return "Sale over!";
    }

    if (
      ticket?.totalFreeQuantity != undefined &&
      ticket?.totalFreeQuantity <= 0
    ) {
      return "Sold Out!";
    }
    if (
      ticket?.totalFreeQuantity != undefined &&
      ticket?.totalFreeQuantity == 1
    ) {
      return "Only last one left!";
    }

    if (
      ticket?.totalFreeQuantity != undefined &&
      ticket?.totalFreeQuantity >= 1 &&
      ticket?.totalFreeQuantity <= 5
    ) {
      return "Only few left!";
    }

    return "Available!";
  }
}

export class ReviewHelpers {
  public static doesReviewsContainReviewFromUser(
    reviews: ReviewV1[],
    userId: string
  ): boolean {
    for (const review of reviews) {
      if (review.reviewer && review.reviewer.id === userId) {
        return true;
      }
    }
    return false;
  }
}
