import React from "react";
import { ImageComponent } from "../../components/common/Image.component";
import { ImageHelpers } from "../../helpers/Helpers";
import { User } from "../../user/User";
import { IOfferingV2 } from "../Offering";
import { OfferingDetailPageDlComponent } from "./Offering.NameContent";
import { OfferingRegistrationSectionComponent } from "./Offering.RegistrationSection.component";

export interface OferingDetailPageContentHeaderProps {
  offering: IOfferingV2;
  user?: User;
}

export function OferingDetailPageContentHeader(
  props: OferingDetailPageContentHeaderProps
) {
  const offeringCommonDetails = props.offering.commonDetails;
  return (
    <>
      {offeringCommonDetails.bannerImage &&
        ImageHelpers.validImage(offeringCommonDetails.bannerImage.data) && (
          <ImageComponent
            image={offeringCommonDetails.bannerImage}
            height={"auto"}
            className="rounded-top w-100"
          />
        )}

      <OfferingDetailPageDlComponent
        className={`${
          !(
            offeringCommonDetails.bannerImage &&
            ImageHelpers.validImage(offeringCommonDetails.bannerImage.data)
          ) && "mt--16px"
        }`}
        offering={props.offering}
        registrationContent={
          <OfferingRegistrationSectionComponent
            offering={props.offering}
            user={props.user?.basicDetails}
          />
        }
      />
    </>
  );
}
