import React from "react";
import { useAlert } from "react-alert";
import { InterviewExperienceAPI } from "../api/InterviewExperienceAPI";
import { UrlHelpers } from "../helpers/Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { InterviewExperience } from "../model/InterviewExperience";
import { PageProps } from "../model/views/PageProps";
import { InterviewExperienceForm } from "./InterviewExperience.Form";

export function AddInterviewExperiencePageContent(props: PageProps) {
  let alert = useAlert();

  function onIEFormSubmit(ie: InterviewExperience) {
    return InterviewExperienceAPI.addInterviewExperience(ie).then(function (
      response
    ) {
      const successMessage = "Interview Experience saved successfully!!!";
      alert.success(successMessage);
      const ieUrl = UrlHelpers.getInterviewExperienceDetailsUrl(
        response.slug,
        response.id,
        successMessage
      );

      WindowHelpers.redirect(ieUrl);
    });
  }

  return (
    <div className="main-container AddInterviewExperiencePageContent">
      {props.user && (
        <>
          <h1 className="h3 mb-4">Add Interview Experience</h1>
          <InterviewExperienceForm
            onSubmit={onIEFormSubmit}
            user={props.user.basicDetails}
          />
        </>
      )}
    </div>
  );
}
