import React from "react";
import { CallerContext } from "../components/common/CallerContext";
import { UrlHelpers } from "../helpers/Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { PageProps } from "../model/views/PageProps";
import { AddOrUpdateQuestionForm } from "./AddOrUpdateQuestionForm";
import { Question } from "./Question.Model";

interface AddQuestionPageContentProps extends PageProps {}

export class AddQuestionPageContent extends React.Component<
  AddQuestionPageContentProps,
  {}
> {
  render() {
    let callBack = (
      question: Question,
      callerContext: CallerContext
    ): Promise<void> => {
      return new Promise<void>((resolve, reject) => {
        const successMessage = "Question saved succesfully!!!";
        const redirectUrl = UrlHelpers.getQuestionDetailsUrl(
          question.slug,
          question.id,
          successMessage
        );
        WindowHelpers.redirect(redirectUrl);
        resolve();
      });
    };
    return (
      <div className="main-container">
        {this.props.user && (
          <>
            <h3>Add Question</h3>

            <div className="card">
              <div className="card-body">
                <AddOrUpdateQuestionForm
                  callerContext={{}}
                  callback={callBack}
                  user={this.props.user.basicDetails}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
