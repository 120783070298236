import React from "react";
import { UserBasicDetails } from "../user/User";
import { EventTypeInput } from "./EventBasicInformation.input";

export interface CreateEventComponentProps {
  user: UserBasicDetails;
}

export function CreateEventComponent(props: CreateEventComponentProps) {
  return (
    <div className="CreateEventComponent">
      {<EventTypeInput user={props.user} />}
    </div>
  );
}
