import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { CommentsComponent } from "../components/common/comments/Comments.component";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { ReactMatch } from "../model/ReactMatchObj";
import { PageProps } from "../model/views/PageProps";
import { RelatedContentComponent } from "../promotion/RelatedContent";
import { RelatedExpertsComponent } from "../promotion/RelatedExperts.Component";
import { Question } from "./Question.Model";
import { QuestionView } from "./Question.view";
import { QuestionsAPI } from "./QuestionsAPI";

interface QuestionDetailPagePathParams {
  questionId: number;
}

interface QuestionDetailPageProps extends PageProps {
  match: ReactMatch<QuestionDetailPagePathParams>;
}

interface QuestionDetailPageState {
  question: Question;
}

export function QuestionDetailPage(props: QuestionDetailPageProps) {
  let [question, setQuestion] = useState<Question>();
  let questionId = props.match.params.questionId;
  useEffect(() => {
    fetchQuestion();
  }, []);
  function fetchQuestion() {
    QuestionsAPI.fetchQuestionDetails(questionId).then((questionResponse) => {
      setQuestion(questionResponse);
    });
  }
  return (
    <div className="main-container QuestionDetailPage">
      {question && (
        <Row gutter={[15, 10]}>
          <Col xs={24} sm={24} md={14} lg={16} xxl={18}>
            <QuestionView
              question={question}
              user={props.user?.basicDetails}
              onSubmitAnswer={fetchQuestion}
            />
            <div className="mt-4">
              <CommentsComponent
                dl="Discussion"
                targetType={AttachmentTargetType.QUESTION}
                targetId={question.id + ""}
                loggedInuser={props.user}
              />
            </div>
            <div className="mt-4">
              <RelatedContentComponent context={{ topics: question.topics }} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={10} lg={8} xxl={6}>
            <RelatedExpertsComponent
              context={{
                topics: [
                  ...(question.topics ?? []),
                  ...(question.companies ?? []),
                ],
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
}
