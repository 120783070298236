import React from "react";
import { useAlert } from "react-alert";
import ReactDOM from "react-dom";
import { CallerContext } from "../components/common/CallerContext";
import { EBModalV2 } from "../components/common/EBModalV2";
import { AddIcon } from "../icon/Add.Icon";
import { ButtonText } from "../model/views/Button.props";
import { UserBasicDetails } from "../user/User";
import { AddOrUpdateQuestionForm } from "./AddOrUpdateQuestionForm";
import { Question } from "./Question.Model";
import { SearchQuestionView } from "./SearchQuestionView";

interface QuestionPickerProps {
  callback: (question: Question, callerContext: CallerContext) => void;
  callerContext: CallerContext;
  uniqueKey: string;
  pickerButtonContent?: ButtonText;
  user: UserBasicDetails;
}

interface QuestionPickerModalProps {
  callback: (question: Question, callerContext: CallerContext) => void;
  callerContext: CallerContext;
  uniqueKey: string;
  user: UserBasicDetails;
}

export function AddQuestionModal(props: QuestionPickerModalProps) {
  let alertManager = useAlert();
  let callBack = (
    question: Question,
    callerContext: CallerContext
  ): Promise<void> => {
    alertManager.success("Question added successfully");

    return new Promise<void>((resolve, reject) => {
      props.callback(question, callerContext);
      resolve();
    });
  };
  return (
    <>
      <EBModalV2
        body={
          <AddOrUpdateQuestionForm
            callback={callBack}
            callerContext={props.callerContext}
            clearQuestionPostSubmit={true}
            user={props.user}
          />
        }
        id={props.uniqueKey + "addQuestionModal"}
        modalSize="xl"
        header="Add New Question"
      />
    </>
  );
}

export function SearchQuestionModal(props: QuestionPickerModalProps) {
  return (
    <>
      <EBModalV2
        body={
          <SearchQuestionView
            callback={props.callback}
            callerContext={props.callerContext}
          />
        }
        id={props.uniqueKey + "searchQuestionModal"}
        modalSize="xl"
        header="Search Question"
        primaryButtonProps={{
          content: {
            normal: (
              <>
                <AddIcon /> Add Question
              </>
            ),
          },
          otherProps: {
            "data-bs-dismiss": "modal",
            "data-bs-toggle": "modal",
            "data-bs-target": "#" + props.uniqueKey + "addQuestionModal",
          },
        }}
      />
    </>
  );
}

export function QuestionPickerView(props: QuestionPickerProps) {
  return (
    <div>
      <button
        type="button"
        className={
          "btn btn-primary " + props.pickerButtonContent?.extraClassNames ?? ""
        }
        data-bs-toggle="modal"
        data-bs-target={"#" + props.uniqueKey + "searchQuestionModal"}
      >
        {props.pickerButtonContent
          ? props.pickerButtonContent.normal
          : "Add Question"}
      </button>

      <QuestionPickerModals {...props} />
    </div>
  );
}

function QuestionPickerModals(props: QuestionPickerProps) {
  const modalsRoot = document.getElementById("modals") as HTMLElement;
  let pickerCallback = (
    question: Question,
    callerContext: CallerContext
  ): void => {
    document
      .getElementById(props.uniqueKey + "addQuestionModal-close-button")
      ?.click();
    document
      .getElementById(props.uniqueKey + "searchQuestionModal-close-button")
      ?.click();
    props.callback(question, callerContext);
  };
  return ReactDOM.createPortal(
    <>
      <SearchQuestionModal
        callback={pickerCallback}
        callerContext={props.callerContext}
        uniqueKey={props.uniqueKey}
        user={props.user}
      />
      <AddQuestionModal
        callback={pickerCallback}
        callerContext={props.callerContext}
        uniqueKey={props.uniqueKey}
        user={props.user}
      />
    </>,
    modalsRoot
  ) as JSX.Element;
}
