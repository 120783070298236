import { Field } from "formik";
import React from "react";
import { EBTextAreaV2 } from "../components/common/Form/FormElements";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { FormikDatePicker } from "../components/common/input/FormikDatePicker";
import { EBTextInputV2 } from "../components/common/input/TextLine.input";
import { EditTimeDurationFormikInput } from "../duration/EditTimeDuration.FormikInput";
import { TimeDurationOptionGranularity } from "../duration/TimeDuration.FormikInput";
import { UserBasicDetails } from "../user/User";

interface VideoSessionInputProps {
  user: UserBasicDetails;
  name?: string;
}

export function VideoSessionInput(props: VideoSessionInputProps) {
  const namePrefix = props.name ? `${props.name}.` : "";
  return (
    <div>
      <div className="row">
        <div className="col-md-6">
          <Label label={"Date & Time:"} required={true} />
          <div>
            <Field
              name={`${namePrefix}scheduleDateTime`}
              placeholder="Start"
              component={FormikDatePicker}
              className="form-control"
              required={true}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <Label
            label={"Meeting Link (You can create meets, zoom, etc.):"}
            required={true}
          />
          <div>
            <EBTextInputV2
              name={`${namePrefix}scheduleMeetingLink`}
              required={true}
              placeholder="Example: https://meets.google.com/abc-pqr"
            />
          </div>
        </div>

        <div className="col-md-6">
          <Label label={"Duration:"} required={true} />
          <EditTimeDurationFormikInput
            timeDurationOptions={[
              {
                valueInSecs: 900,
                displayLabel: "15 mins",
              },
              {
                valueInSecs: 1800,
                displayLabel: "30 mins",
              },
              {
                valueInSecs: 3600,
                displayLabel: "1 hour",
              },
              {
                valueInSecs: 7200,
                displayLabel: "2 hours",
              },
            ]}
            required
            name={`${namePrefix}durationSeconds`}
            customInputGranularity={TimeDurationOptionGranularity.MINS}
            supportCustom={true}
          />
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-md-12">
          <EBTextAreaV2
            required
            name={`${namePrefix}agenda`}
            placeholder="Briefly tell what will you provide in this."
            label={"Agenda:"}
            enablePerformance={true}
          />
        </div>
      </div>
    </div>
  );
}
