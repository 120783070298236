import React from "react";
import { TimestampDurationView } from "../components/common/TimestampView";
import { EventV2 } from "./Event.Model";

import {
  AddMessageView,
  MessagesLisView,
} from "../components/common/messages/Message.view";
import { ReviewHelpers } from "../helpers/Helpers";
import { ReactMatch } from "../model/ReactMatchObj";
import { ReviewV1 } from "../model/Reviews";
import { VenueTypeEnum } from "../model/Venue.model";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { MessageResponse } from "../model/response/Message.response";
import { PageProps } from "../model/views/PageProps";
import { User } from "../user/User";
import { EventApi } from "./Event.Api";
import {
  EventAttendeesListView,
  EventCompletionButton,
  EventReviewForm,
  EventReviewsListView,
} from "./Events.view";

interface EventDashboardContentState {
  event: EventV2;
  attendees: User[];
  messages: MessageResponse[];
  reviews: ReviewV1[];
}

interface EventDashboardContentPathParams {
  eventId: string;
}

interface EventDashboardContentProps extends PageProps {
  match: ReactMatch<EventDashboardContentPathParams>;
}
export class EventDashboardContent extends React.Component<
  EventDashboardContentProps,
  EventDashboardContentState
> {
  componentDidMount() {
    EventApi.get(this.props.match.params.eventId, FetchContext.PUBLIC).then(
      (event: EventV2) => {
        this.setState({ event: event });
        EventApi.getAttendees(this.props.match.params.eventId).then(
          (response) => {
            this.setState({ attendees: response });
          }
        );
        EventApi.getMessages(this.props.match.params.eventId).then(
          (response) => {
            this.setState({ messages: response });
          }
        );
        EventApi.getReviews(this.props.match.params.eventId).then(
          (response) => {
            this.setState({ reviews: response });
          }
        );
      }
    );
  }

  render() {
    return (
      <div className="main-container">
        {this.state && this.props.user && this.state.event && (
          <>
            <div className="mb-4">
              <div className="hstack gap-4">
                <h2>{this.state.event.basicDetails.title}</h2>
                <div className="ms-auto">
                  {this.state.event.basicDetails.completedAt &&
                    "Event Completed"}
                </div>
                {!this.state.event.basicDetails.completedAt &&
                  this.props.user &&
                  this.state.event.basicDetails.createdById ===
                    this.props.user.basicDetails.id && (
                    <div className="ms-auto">
                      <EventCompletionButton event={this.state.event} />
                    </div>
                  )}
              </div>
              <div>
                <b>
                  Event's time:{" "}
                  <mark>
                    {this.state.event.basicDetails.duration && (
                      <TimestampDurationView
                        startTimestamp={
                          this.state.event.basicDetails.duration?.fromMillis
                        }
                        endTimestamp={
                          this.state.event.basicDetails.duration?.toMillis
                        }
                      />
                    )}
                  </mark>
                </b>
              </div>
              <div>
                <b>
                  Event's Venue:
                  <mark>
                    {this.state.event.basicDetails.venue &&
                      this.state.event.basicDetails.venue.venueType ==
                        VenueTypeEnum.ONLINE && (
                        <a
                          href={this.state.event.basicDetails.venue.venueData}
                          target="_blank"
                        >
                          {this.state.event.basicDetails.venue.venueData}
                        </a>
                      )}
                    {this.state.event.basicDetails.venue &&
                      this.state.event.basicDetails.venue.venueType ==
                        VenueTypeEnum.PHYSICAL_LOCATION && (
                        <p>{this.state.event.basicDetails.venue.venueData}</p>
                      )}
                  </mark>
                </b>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                {this.state &&
                  this.state.event &&
                  !this.state.event.basicDetails.completedAt && (
                    <div className="card mb-4">
                      <div className="card-body">
                        {this.props.user && this.state.event && (
                          <AddMessageView
                            eventId={this.state.event.id}
                            user={this.props.user?.basicDetails}
                          />
                        )}
                      </div>
                    </div>
                  )}

                {this.props.user &&
                  this.state.event.canAddReview &&
                  this.state.reviews &&
                  !ReviewHelpers.doesReviewsContainReviewFromUser(
                    this.state.reviews,
                    this.props.user.basicDetails.id
                  ) && (
                    <div className="card mb-4">
                      <div className="card-body">
                        <EventReviewForm event={this.state.event} />
                      </div>
                    </div>
                  )}

                <div className="card">
                  <div className="card-header">Past Messages</div>
                  <div className="card-body">
                    {this.state && this.state.messages && (
                      <MessagesLisView messages={this.state.messages} />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {this.state &&
                  this.state.event &&
                  this.state.event.basicDetails.completedAt &&
                  this.state.attendees && (
                    <div className="mb-2">
                      <EventReviewsListView reviews={this.state.reviews} />
                    </div>
                  )}
                {this.state && this.state.attendees && (
                  <div className="">
                    <EventAttendeesListView attendees={this.state.attendees} />
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
