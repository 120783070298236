import { Col, Row } from "antd";
import Tabs, { TabsProps } from "antd/es/tabs";
import React from "react";
import TimeAgo from "timeago-react";
import { OfferReceivedBadge } from "../badge/OfferReceived.Badge";
import { PendingApprovalBadge } from "../badge/PendingApproval.Badge";
import { CopyClipboardButton } from "../components/common/CopyCliboard.button";
import { EditTitle } from "../components/common/Edit.title";
import { ImageComponent } from "../components/common/Image.component";
import { ReactionCountsViewComponent } from "../components/common/ReactionCountsViewComponent";
import { TextViewComponent } from "../components/common/TextView";
import { UrlHelpers } from "../helpers/Helpers";
import { WindowHelpers } from "../helpers/Window.helper";
import { Link } from "../links/Link";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { InterviewExperienceResponse } from "../model/InterviewExperience";
import { InterviewExperienceRound } from "../model/InterviewExperienceRound";
import { QuestionAccordionView } from "../question/Question.view";
import { UserLink } from "../user/User.link";

interface InterviewExperienceDetailsProps {
  interviewExperience: InterviewExperienceResponse;
}

interface InterviewExperienceRoundViewProps {
  round: InterviewExperienceRound;
}

function InterviewExperienceRoundView(
  props: InterviewExperienceRoundViewProps
) {
  let round = props.round;
  return (
    <div>
      <Row align={"middle"}>
        <Col flex="auto">
          <h6 className="mb-0">{round.name}</h6>
        </Col>
        <Col>{round.durationInMinutes} minutes</Col>
      </Row>
      {round.roundDescription && (
        <p>
          <TextViewComponent text={round.roundDescription} />
        </p>
      )}
      {round.questions && round.questions.length > 0 && (
        <>
          <div className="mb-2">Problems Asked:</div>
          {round.questions.map((question, index) => {
            return (
              <p key={index}>
                <QuestionAccordionView question={question} expanded={true} />
              </p>
            );
          })}
        </>
      )}
    </div>
  );
}

export function InterviewExperienceDetails(
  props: InterviewExperienceDetailsProps
) {
  let ie = props.interviewExperience;

  const items: TabsProps["items"] = [
    {
      key: "summary",
      label: "Summary",
      children: (
        <div className="text-change-line-for-newline">{ie.summary}</div>
      ),
    },
  ];

  if (ie.rounds) {
    for (let round of ie.rounds) {
      items.push({
        key: round.number + "",
        label: "Round " + round.number,
        children: <InterviewExperienceRoundView round={round} />,
      });
    }
  }

  const onTabChange = (key: string) => {
    console.log(key);
  };

  return (
    <div className="">
      {ie ? (
        <div>
          <div className="py-2 pb-3">
            <div className="media">
              {ie.company?.dp && (
                <ImageComponent
                  image={ie.company.dp}
                  className="rounded"
                  width={WindowHelpers.isMobile() ? "50px" : "100px"}
                />
              )}
              <div className={`media-body pl-4 mob-pl-3`}>
                <div className="d-flex w-100 justify-content-between">
                  <h1 className="mb-1 fw-normal h3">{ie.dl}</h1>
                </div>

                <div className="d-flex w-100 justify-content-between">
                  <div>
                    <small className="text-muted">
                      {ie.createdAt && (
                        <>
                          Posted <TimeAgo datetime={ie.createdAt} />{" "}
                        </>
                      )}
                      {!ie.isAnon && (
                        <>
                          by <UserLink user={ie.postedBy} />
                        </>
                      )}

                      <div>{ie.receivedOffer && <OfferReceivedBadge />}</div>
                      {ie.reactionCounts && (
                        <div>
                          <ReactionCountsViewComponent
                            targetType={
                              AttachmentTargetType.INTERVIEW_EXPERIENCE
                            }
                            targetId={"" + ie.id}
                            reactionCounts={ie.reactionCounts}
                          />
                        </div>
                      )}
                      {ie.shareContent && (
                        <CopyClipboardButton
                          className="text-secondary mt-2"
                          textToCopy={ie.shareContent}
                          dl="Copy Content To Share"
                          successDl="Content Copied To Clipboard."
                        />
                      )}
                    </small>
                  </div>
                  <div>
                    {ie.canUserEdit && (
                      <Link
                        href={UrlHelpers.getEditInterviewExperienceUrl(
                          ie.slug,
                          ie.id
                        )}
                        className="link-secondary btn-lg float-right"
                      >
                        <EditTitle text="Edit" />
                      </Link>
                    )}
                    <div>{!ie.approvedAt && <PendingApprovalBadge />}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Tabs
            defaultActiveKey="summary"
            items={items}
            onChange={onTabChange}
          />
        </div>
      ) : null}
    </div>
  );
}
