import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { ApiErrorHelper } from "../../../helpers/ApiError.helper";
import { ButtonText } from "../../../model/views/Button.props";
import { Topic } from "../../../topic/Topic.model";
import { TopicSelector } from "../../../topic/TopicSelector.component";
import { User } from "../../../user/User";
import { CallerContext } from "../../common/CallerContext";
import { EBButton } from "../../common/EBButton";
import { EBTextInput, FormInputLabel } from "../../common/eb-ui-components";
import { MarkdownInput } from "../../common/input/Markdown.input";
import { CharchaaAPI } from "./Charchaa.api";
import { Post } from "./Charchaa.model";

interface CharchaaAddOrUpdatePostContentFormProps {
  callback: (post: Post, callerContext: CallerContext) => Promise<void>;
  callerContext: CallerContext;
  addButtonText?: ButtonText;
  post?: Post;
  allTopics: Topic[];
  user: User;
  clearQuestionPostSubmit?: boolean;
}
const EMPTY_POST_ID = -1;
export function CharchaaAddOrUpdatePostContent(
  props: CharchaaAddOrUpdatePostContentFormProps
) {
  const alertManager = useAlert();
  let [content, setContent] = useState(props.post ? props.post.content : "");

  function getEmptyPost(): Post {
    return {
      id: EMPTY_POST_ID,
      title: "",
      content: "",
      isAnon: false,
      topics: [],
    };
  }
  return (
    <div className="CharchaaAddOrUpdatePostContent">
      <Formik
        initialValues={props.post ?? ({} as Post)}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          let api: Promise<Post>;
          if (values.id == undefined || values.id === EMPTY_POST_ID) {
            api = CharchaaAPI.addPost(values);
          } else {
            api = CharchaaAPI.updatePostById(values.id, values);
          }
          api
            .then(function (post: Post) {
              if (props.clearQuestionPostSubmit) {
                actions.resetForm({
                  values: getEmptyPost(),
                });
                setContent("");
              }
              props.callback(post, props.callerContext).then(() => {
                actions.setSubmitting(false);
              });
            })
            .catch(function (error) {
              let displayError = ApiErrorHelper.getDisplayErrorMessage(error);
              alertManager.error(displayError);
            })
            .finally(() => {
              actions.setSubmitting(false);
            });
        }}
      >
        {({ values, handleSubmit, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <input type="hidden" name="id" value={values.id} />
            <div className="row">
              <div className="col-md-6">
                <EBTextInput
                  label="Title:"
                  name="title"
                  placeholder="Add title for the post"
                  helpText=""
                  required
                />
              </div>

              <div className="col-md-6">
                <TopicSelector
                  label="Topics:"
                  topics={props.allTopics}
                  name={"topics"}
                  isMulti={true}
                  required={true}
                />
              </div>
            </div>
            <div className="row mob-mt-3">
              <div className="col">
                <div className="form-group">
                  <FormInputLabel label="Content:" required />
                  <MarkdownInput
                    name={"content"}
                    required={true}
                    minEditorHeight={400}
                    user={props.user.basicDetails}
                  />
                </div>
              </div>
            </div>

            <div className="row pt-2">
              <div className="col">
                <EBButton
                  classes={["btn btn-primary"]}
                  text={
                    props.addButtonText ?? {
                      normal: "Save",
                      submitting: "Saving ",
                    }
                  }
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                />
              </div>
              <div className="col text-end">
                <Field
                  type="checkbox"
                  id="isAnon"
                  className="custom-checkbox custom-control-input"
                  name="isAnon"
                />
                <label className="custom-control-label" htmlFor="isAnon">
                  Anonymous?
                </label>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
