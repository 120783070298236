import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Label } from "../components/common/Form/elements/FormInputLabel";
import { AttachmentTargetType } from "../model/AttachmentTargetType";
import { EditOfferingOptionsComponent } from "../offerings/EditOfferingOptions.component";
import { OfferingTypeName, SessionType } from "../offerings/Offering";
import { OfferingInputSection } from "../offerings/Offering.Input.Section";
import { OfferingsEditComponent } from "../offerings/Offerings.Edit.Component";
import { SessionTypeSelector } from "../offerings/SessionTyoeSelector.component";
import { OfferingTextQueryAPI } from "../offerings/text-query/Offering.TextQuery.api";
import { ExpertStudioContentProps } from "./ExpertStudio.page";

interface OfferingsTextQueryEditExpertStudioContentProps
  extends ExpertStudioContentProps {}

export function OfferingsTextQueryEditExpertStudioContent(
  props: OfferingsTextQueryEditExpertStudioContentProps
) {
  let [textQueryTypes, setSessionTypes] = useState<SessionType[]>([]);

  useEffect(() => {
    OfferingTextQueryAPI.getTextQueryTypes().then((sessionTypes) => {
      setSessionTypes(sessionTypes);
    });
  }, []);
  let alert = useAlert();
  return (
    <div className="OfferingsTextQueryEditExpertStudioContent">
      <h5>Edit Text Query Offerings:</h5>

      {textQueryTypes && (
        <OfferingsEditComponent
          user={props.user}
          offeringType={OfferingTypeName.TEXT_QUERY}
          offeringTargetType={AttachmentTargetType.OFFERING_TEXT_QUERY}
          titleSideNode={
            <OfferingInputSection
              title={<Label label={"Type:"} required={true} />}
              content={
                <SessionTypeSelector
                  all={textQueryTypes}
                  name={`sessionType`}
                  required={true}
                  fieldTitle=""
                />
              }
            />
          }
          postTitleNode={
            <OfferingInputSection
              title={<Label label={"Options:"} required={true} />}
              content={
                <EditOfferingOptionsComponent
                  offeringSessionTypeFieldName={"sessionType"}
                  name={`commonDetails.options`}
                  skills={[]}
                  sessionTypes={textQueryTypes}
                  user={props.user.basicDetails}
                />
              }
            />
          }
        />
      )}
    </div>
  );
}
