import { FieldArray, useField } from "formik";
import React, { useState } from "react";
import { DeleteTitle } from "../components/common/Delete.title";
import { EBButtonV2 } from "../components/common/EBButtonV2";
import { EditTitle } from "../components/common/Edit.title";
import { Mode } from "../components/common/Form/model/mode.model";
import { MarkdownViewV1 } from "../components/common/MarkdownView";
import { FormikInputParams } from "../components/common/input/FormikInputParams.model";
import { FormikSwitchInput } from "../components/common/input/FormikSwitch.input";
import { MarkdownInput } from "../components/common/input/Markdown.input";
import { UserBasicDetails } from "../user/User";
import { QuestionHelper } from "./Question.Helper";
import {
  QuestionTypeDataMcq,
  QuestionTypeDataMcqOption,
  QuestionTypeEnum,
  QuestionTypeInfo,
} from "./Question.Model";
import { QuestionTypeSelector } from "./QuestionType.Select";

export function QuestionTypeInfoInput(props: FormikInputParams) {
  let [field] = useField(props);
  let currentTypeInfo: QuestionTypeInfo = field.value;
  return (
    <div className="QuestionTypeInfoInput">
      <QuestionTypeSelector
        questionTypes={QuestionHelper.getQuestionTypeList()}
        name={`${props.name}.type`}
      />
      {currentTypeInfo?.type?.typeEnum && (
        <div className="mt-3">
          {currentTypeInfo?.type?.typeEnum === QuestionTypeEnum.MCQ && (
            <>
              <QuestionTypeMCQSpecificInput name={`${props.name}.data`} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export function QuestionTypeMCQSpecificInput(props: FormikInputParams) {
  let [field] = useField(props);
  let fieldValue: QuestionTypeDataMcq = field.value;

  return (
    <div className="QuestionTypeMCQSpecificInput">
      This question type requires options to be added. Please add them below
      (Please select atleast one correct option also):
      <FieldArray name={`${props.name}.options`}>
        {(arrayHelpers: any) => (
          <div className="mt-2">
            {fieldValue &&
              fieldValue.options &&
              fieldValue.options.map((option, index) => (
                <div key={index} className="row">
                  <div className="col-auto pr-1 pt-1 ">
                    <MarkdownViewV1>{index + 1 + ":"}</MarkdownViewV1>
                  </div>
                  <div className="col pl-0 ">
                    <QuestionTypeMCQOptionInput
                      name={`${props.name}.options.${index}`}
                      onDelete={() => {
                        arrayHelpers.remove(index);
                      }}
                    />
                  </div>
                </div>
              ))}
            <EBButtonV2
              className="mt-2"
              content={{ normal: "Add Option" }}
              onClick={() => {
                arrayHelpers.push({});
              }}
            />
          </div>
        )}
      </FieldArray>
    </div>
  );
}

interface QuestionTypeMCQOptionInputProps extends FormikInputParams {
  onDelete: () => void;
}

function QuestionTypeMCQOptionInput(props: QuestionTypeMCQOptionInputProps) {
  let [mode, setMode] = useState<Mode>(Mode.VIEW);
  let [field] = useField(props);
  let fieldValue: QuestionTypeDataMcqOption = field.value;
  return (
    <>
      {mode == Mode.EDIT && (
        <div>
          <MarkdownInput
            user={{} as UserBasicDetails}
            name={`${props.name}.text`}
            minEditorHeight={100}
          />
          <div className="float-right hstack gap-2">
            <FormikSwitchInput
              name={`${props.name}.isCorrect`}
              label="Is this a correct option?"
            />
            <EBButtonV2
              className="mt-2 mb-2"
              content={{ normal: "Done" }}
              onClick={() => {
                setMode(Mode.VIEW);
              }}
            />
          </div>
        </div>
      )}

      {mode == Mode.VIEW && (
        <div
          className={`bg-light mb-2 pt-1 px-2 rounded-1 border border-1 ${
            fieldValue.isCorrect ? "border-success" : ""
          } `}
        >
          <div className="row">
            <div className="col ">
              <div
                onClick={() => {
                  setMode(Mode.EDIT);
                }}
              >
                <MarkdownViewV1>
                  {fieldValue.text ?? "Edit to add option details"}
                </MarkdownViewV1>
              </div>
            </div>

            <div className="col-auto hstack gap-2">
              <EBButtonV2
                content={{ normal: <EditTitle /> }}
                className="btn-light btn-sm"
                onClick={() => {
                  setMode(Mode.EDIT);
                }}
              />
              <EBButtonV2
                content={{ normal: <DeleteTitle /> }}
                className="btn-light btn-sm"
                onClick={props.onDelete}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
