import React, { MutableRefObject, useEffect, useState } from "react";
import Moment from "react-moment";
import { Loader } from "../components/common/Loader.component";
import { MarkdownViewV1 } from "../components/common/MarkdownView";
import { PriceView } from "../components/common/Price.view";
import { TimestampDurationView } from "../components/common/TimestampView";
import { Center } from "../components/common/layout/Center.component";
import { VenueTypeEnum } from "../model/Venue.model";
import { FetchContext } from "../model/enums/FetchContext.enum";
import { UserBasicDetails } from "../user/User";
import { UserLink } from "../user/User.link";
import { EventApi } from "./Event.Api";
import { EventV2 } from "./Event.Model";
import { ImageHelpers } from "../helpers/Helpers";
import { ImageComponent } from "../components/common/Image.component";
import { EventPreviewPageView } from "./EventPreviewPageView";

export interface PublishEventComponentProps {
  user: UserBasicDetails;
  eventId: MutableRefObject<string | undefined>;
}

export function PublishEventComponent(props: PublishEventComponentProps) {
  return (
    <div className="PublishEventComponent">
      {
        <PublishEventComponentPageDetail
          user={props.user}
          eventId={props.eventId}
        />
      }
    </div>
  );
}

export interface PublishEventComponentPageDetailProps {
  user: UserBasicDetails;
  eventId: MutableRefObject<string | undefined>;
}

export function PublishEventComponentPageDetail(
  props: PublishEventComponentPageDetailProps
) {
  let [loading, setLoading] = useState<boolean>();
  let [event, setEvent] = useState<EventV2>();

  useEffect(() => {
    if (props.eventId.current) {
      setLoading(true);

      EventApi.get(props.eventId.current, FetchContext.EDIT)
        .then((event) => {
          setEvent(event);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [props.eventId.current]);
  return (
    <div className="PublishEventComponentPageDetail">
      {loading && (
        <Center>
          <Loader />
        </Center>
      )}
      {!loading && event && (
        <>
          <EventPreviewPageView event={event} preview={true} />
          {/* <div>
            <div className="">
              {event?.basicDetails.bannerImage &&
                ImageHelpers.validImage(
                  event?.basicDetails.bannerImage.data
                ) && (
                  <ImageComponent
                    image={event?.basicDetails.bannerImage}
                    width={"100%"}
                    height={"auto"}
                  />
                )}
            </div>
            <div className="mt-2">
              <span className="font-weight-bold">Basic Detaails</span>
            </div>
            <div className="row mt-2">
              <span>
                Title: &nbsp; <b>{event?.basicDetails.title}</b>
              </span>
              <span>
                Start Date: &nbsp;
                <Moment format="DD-MM-YYYY H:MM">
                  {event?.basicDetails.duration?.fromMillis}
                </Moment>
              </span>
              <span>
                End Date: &nbsp;
                <Moment format="DD-MM-YYYY H:MM">
                  {event?.basicDetails.duration?.toMillis}
                </Moment>
              </span>
              <span>
                Venue Type: &nbsp;{" "}
                {`${
                  event?.basicDetails.venue?.venueType ==
                  VenueTypeEnum.PHYSICAL_LOCATION
                    ? "Physical Location"
                    : "Online"
                }`}
              </span>
              <span>Venue : &nbsp; {event?.basicDetails.venue?.venueData}</span>
              {event?.basicDetails.description && (
                <span>
                  Description:
                  <div className="card card-body p-2">
                    <MarkdownViewV1>
                      {event?.basicDetails.description}
                    </MarkdownViewV1>
                  </div>
                </span>
              )}

              <div>
                <div className="mt-2">
                  <span className="font-weight-bold">Instructor Details</span>
                </div>
                <div className="row mt-2">
                  {event.instructors.map((instructor, index) => (
                    <>
                      <UserLink user={instructor} />
                    </>
                  ))}
                </div>
              </div>
              <div>
                <div className="mt-2">
                  <span className="font-weight-bold">Tickets Details</span>
                </div>
                {event?.tickets?.map((ticket, index) => (
                  <div className="card mt-2">
                    <div className="card-header mt-2">
                      <span className="font-weight-bold">
                        Ticket {index + 1}: {ticket.title}
                      </span>
                    </div>
                    <div className="row p-3">
                      {ticket.saleDuration && (
                        <div>
                          Sale Duration: &nbsp;{" "}
                          <TimestampDurationView
                            startTimestamp={ticket.saleDuration.fromMillis}
                            endTimestamp={ticket.saleDuration.toMillis}
                          />
                        </div>
                      )}
                      <span>
                        Available Ticket Quantity : &nbsp;{" "}
                        {ticket?.availableQuantity}
                      </span>
                      {ticket.price && (
                        <span>
                          Price : &nbsp;{" "}
                          <PriceView
                            price={ticket.price}
                            showFree={true}
                          ></PriceView>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div> */}
        </>
      )}
    </div>
  );
}
