import { Col, Row } from "antd";
import { useField } from "formik";
import React, { useState } from "react";
import { apiLinkConstants } from "../../../constants";
import { ImageHelpers } from "../../../helpers/Helpers";
import { Link } from "../../../links/Link";
import { AssetLocation, LocationType } from "../../../model/Picture";
import { FileUploadComponent } from "../../../upload/FileUpload.component";
import { UserBasicDetails } from "../../../user/User";
import { EBButtonV2 } from "../EBButtonV2";
import { EditTitle } from "../Edit.title";
import { Mode } from "../Form/model/mode.model";
import { ImageComponent } from "../Image.component";
import { Tab, TabView } from "../Tab.view";
import { FormInputLabel } from "../eb-ui-components";
import { FormikHiddenInput } from "../input/FormikHiddentInput";
import { FormikInputParams } from "../input/FormikInputParams.model";
import { EBTextInputV2 } from "../input/TextLine.input";

export interface AttachmentInputProps extends FormikInputParams {
  user: UserBasicDetails;
}

export function AttachmentInput(props: AttachmentInputProps) {
  const [field] = useField(props);
  const existingAsset: AssetLocation | undefined = field.value;
  const [mode, setMode] = useState<Mode>(
    existingAsset?.data ? Mode.VIEW : Mode.EDIT
  );

  function toggleMode() {
    if (mode === Mode.VIEW) {
      setMode(Mode.EDIT);
    } else {
      setMode(Mode.VIEW);
    }
  }
  const tabs: Tab[] = [
    {
      title: "Upload",
      content: (
        <>
          <FileInput
            {...props}
            onUpdate={(image: AssetLocation) => {
              toggleMode();
              return Promise.resolve();
            }}
          />
        </>
      ),
      uniqueKey: "upload",
    },
    {
      title: "External link",
      content: (
        <>
          <FormikHiddenInput
            value={LocationType.URL}
            name={`${props.name}.locationType`}
          />
          <FormInputLabel label="Link:" />
          <EBTextInputV2
            name={`${props.name}.data`}
            placeholder="Please input the link of the external item which you want to provide in this offering"
          />
        </>
      ),
      uniqueKey: "external",
    },
  ];

  return (
    <>
      {existingAsset?.data && (
        <>
          <Row align="top">
            <Col>
              {ImageHelpers.validImage(existingAsset?.data) ? (
                <ImageComponent image={existingAsset} maxWidth={"300px"} />
              ) : (
                <Link href={existingAsset.data} target="_blank">
                  Current Item
                </Link>
              )}
            </Col>
            <Col>
              <EBButtonV2
                content={{ normal: <EditTitle /> }}
                className="btn-sm btn-light ml-2"
                onClick={() => {
                  toggleMode();
                }}
              />
            </Col>
          </Row>
          <div className="align-top" style={{ verticalAlign: "top" }}></div>
          <div className="mt-1"></div>
        </>
      )}
      {mode == Mode.EDIT && (
        <div className="mt-2">
          <TabView tabs={tabs} />
        </div>
      )}
    </>
  );
}

interface FileInputProps extends FormikInputParams {
  user: UserBasicDetails;
  onUpdate?: (asset: AssetLocation) => Promise<void>;
}
function FileInput(props: FileInputProps) {
  const [field] = useField(props);

  return (
    <>
      <FileUploadComponent
        onImageUpload={function (asset: AssetLocation): Promise<void> {
          field.onChange({
            target: {
              name: props.name,
              value: asset,
            },
          });
          if (props.onUpdate) {
            return props.onUpdate(asset);
          }
          return Promise.resolve();
        }}
        api={apiLinkConstants.API_FILE_UPLOAD_BY_USER.replace(
          "{userId}",
          props.user.id
        )}
        required={props.required}
      />
    </>
  );
}
