import React, { useEffect, useState } from "react";
import { BookingCommonDetails } from "../../../booking/Booking.model";
import { UserAvailabilityDateTimeSlotsComponent } from "../../../calendar/UserAvailabilityDateTimeSlots.component";
import { apiLinkConstants, publicLinkConstants } from "../../../constants";
import VideoSession, {
  ExpertSessionRole,
  VideoSessionRescheduleByMenteeStatus,
} from "../../../model/ExpertSession";
import { Api, ApiMethod } from "../ApiLinkedButton";
import { ApiLinkedButtonV2 } from "../Form/ApiLinked.button";
import { EBTextAreaV2 } from "../Form/FormElements";
import { FormikHiddenInput } from "../input/FormikHiddentInput";
import { reloadWindowPromise } from "../reloadCallback";

interface VideoSessionRescheduleComponentProps {
  session: VideoSession;
  bcd: BookingCommonDetails;
}

export function VideoSessionRescheduleComponent(
  props: VideoSessionRescheduleComponentProps
) {
  return (
    <div className="VideoSessionRescheduleComponent mb-2">
      {(props.bcd.role === ExpertSessionRole.EXPERT ||
        props.bcd.role === ExpertSessionRole.ADMIN) && (
        <VideoSessionRescheduleExpertComponent {...props} />
      )}

      {(props.bcd.role === ExpertSessionRole.MENTEE ||
        props.bcd.role === ExpertSessionRole.ADMIN) && (
        <VideoSessionRescheduleMenteeComponent {...props} />
      )}
    </div>
  );
}

export function VideoSessionRescheduleExpertComponent(
  props: VideoSessionRescheduleComponentProps
) {
  return (
    <div>
      {props.session.expertRescheduleMarked && (
        <div className="text-danger">
          The session is marked as "In Reschedule". The current schedule is old
          one. It will be updated once mentee picks a new slot. We have already
          notified and asked mentee to update the same.
        </div>
      )}
      {!props.session.expertRescheduleMarked && (
        <ApiLinkedButtonV2
          uniqueKey={`prr-${props.session.id}-expert-reschedule`}
          buttonProps={{
            text: { normal: "Request Reschedule" },
          }}
          api={
            new Api<{ comments: string }, void>(
              apiLinkConstants.API_VIDEO_SESSION_EXPERT_RESCHEDULE_REQUEST.replaceAll(
                "{sessionId}",
                props.session.id
              ),
              ApiMethod.POST
            )
          }
          successCallback={reloadWindowPromise}
          apiFormBody={
            <div>
              Reschedule request goes to mentee so that they can update the
              schedule by picking a new slot.
              <EBTextAreaV2
                label={
                  "Please provide a reason for rescheduling (Will be shown to the mentee):"
                }
                name={"comments"}
                required={true}
                helpText=""
              />
              <div className="alert alert-warning">
                Please make sure your availability is updated correctly in{" "}
                <a
                  target="_blank"
                  href={publicLinkConstants.EXPERT_STUDIO_CALENDAR}
                >
                  expert studio
                </a>{" "}
                before raising this reschedule request.
              </div>
            </div>
          }
        />
      )}
    </div>
  );
}

export function VideoSessionRescheduleMenteeComponent(
  props: VideoSessionRescheduleComponentProps
) {
  let [rescheduleStatus, setRescheduleStatus] =
    useState<VideoSessionRescheduleByMenteeStatus>();
  useEffect(() => {
    new Api<void, VideoSessionRescheduleByMenteeStatus>(
      apiLinkConstants.API_VIDEO_SESSION_MENTEE_RESCHEDULE_STATUS.replace(
        "{sessionId}",
        props.session.id
      ),
      ApiMethod.GET
    )
      .execute()
      .then((rescheduleStatus) => {
        setRescheduleStatus(rescheduleStatus);
      });
  }, []);
  return (
    <div>
      {!props.session.expertRescheduleMarked && (
        <>
          {rescheduleStatus && rescheduleStatus.isRescheduleAllowed && (
            <>
              <ApiLinkedButtonV2
                uniqueKey={`prr-${props.session.id}-mentee-reschedule`}
                buttonProps={{
                  text: { normal: "Reschedule" },
                }}
                api={
                  new Api<{ comments: string }, void>(
                    apiLinkConstants.API_VIDEO_SESSION_MENTEE_RESCHEDULE.replaceAll(
                      "{sessionId}",
                      props.session.id
                    ),
                    ApiMethod.POST
                  )
                }
                successCallback={reloadWindowPromise}
                apiFormBody={
                  <div className="row">
                    <div className="col-md-5">
                      <EBTextAreaV2
                        label={
                          "Reason for rescheduling (Same will be shown to the expert):"
                        }
                        name={"comments"}
                        required={true}
                      />
                    </div>
                    <div className="col-md-7">
                      <UserAvailabilityDateTimeSlotsComponent
                        userIdToShow={props.bcd.engine!.id}
                        durationMins={props.session.durationSeconds / 60}
                        onTimeSlotChange={() => {}}
                        name={"newSlot"}
                      />
                    </div>
                  </div>
                }
              />
            </>
          )}
          {rescheduleStatus && !rescheduleStatus.isRescheduleAllowed && (
            <div>
              Reschedule not allowed because:{" "}
              {rescheduleStatus.disallowDisplayMsg}
            </div>
          )}
        </>
      )}

      {props.session.expertRescheduleMarked && (
        <div className="row">
          <div className="col">
            <div className="text-danger">
              The session is marked as "In Reschedule". The current schedule is
              old one. Please pick a new slot.
            </div>
            <b>Reason expert provided for reschedule:</b>{" "}
            {props.session.expertRescheduleNotes}
          </div>
          <div className="col-auto">
            <ApiLinkedButtonV2
              uniqueKey={`prr-${props.session.id}-mentee-update-schedule`}
              buttonProps={{
                text: { normal: "Update Schedule" },
              }}
              api={
                new Api<{ comments: string }, void>(
                  apiLinkConstants.API_VIDEO_SESSION_MENTEE_SCHEDULE_UPDATE.replaceAll(
                    "{sessionId}",
                    props.session.id
                  ),
                  ApiMethod.POST
                )
              }
              successCallback={reloadWindowPromise}
              apiFormBody={
                <div className="row">
                  <FormikHiddenInput
                    name={"comments"}
                    value={"Reschedule request from expert."}
                  />

                  <div className="col-md-12">
                    <UserAvailabilityDateTimeSlotsComponent
                      userIdToShow={props.bcd.engineId}
                      durationMins={props.session.durationSeconds / 60}
                      onTimeSlotChange={() => {}}
                      name={"newSlot"}
                    />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
