import React from "react";
import { ButtonText } from "../../model/views/Button.props";
import { User, UserBasicDetails } from "../../user/User";
import { SearchUserView } from "./SearchUserView";

interface SearchUserModalProps {
  RowItemComponent: React.ComponentType<{ user: User }>;
  uniqueKey: string;
  modalCloseButtonId: string;
  title?: string;
  user: UserBasicDetails;
}

function SearchUserModal(modalProps: SearchUserModalProps) {
  return (
    <div
      className="modal fade"
      data-bs-backdrop="static"
      id={modalProps.uniqueKey + "searchModal"}
      tabIndex={-1}
      aria-labelledby={modalProps.uniqueKey + "searchModalTitle"}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title"
              id={modalProps.uniqueKey + "searchModalTitle"}
            >
              {modalProps && modalProps.title ? modalProps.title : "Search"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <SearchUserView
              RowItemComponent={modalProps.RowItemComponent}
              callerContext={{}}
              user={modalProps.user}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              id={modalProps.modalCloseButtonId}
            >
              Close
            </button>
            {/* <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              data-bs-toggle="modal"
              data-bs-target={"#" + modalProps.uniqueKey + "addModal"}
            >
              Add New User
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export interface UserPickerViewProps {
  RowItemComponent: React.ComponentType<{ user: User }>;
  uniqueKey: string;
  modalCloseButtonId: string;
  pickerButtonContent?: ButtonText;
  user: UserBasicDetails;
}

export function UserPickerView(pickerProps: UserPickerViewProps) {
  return (
    <div>
      <button
        type="button"
        className="btn btn-sm btn-primary"
        data-bs-toggle="modal"
        data-bs-target={"#" + pickerProps.uniqueKey + "searchModal"}
      >
        {pickerProps && pickerProps.pickerButtonContent?.normal ? (
          pickerProps.pickerButtonContent.normal
        ) : (
          <i className="fas fa-edit"></i>
        )}
      </button>

      <UserPickerModals {...pickerProps} />
    </div>
  );
}

function UserPickerModals(props: UserPickerViewProps) {
  return (
    <>
      <SearchUserModal
        uniqueKey={props.uniqueKey}
        modalCloseButtonId={props.modalCloseButtonId}
        RowItemComponent={props.RowItemComponent}
        title="Search User"
        user={props.user}
      />
    </>
  );
}
