import React from "react";
import { Route } from "react-router";
import { AdminExpertsPageContent } from "../components/admin/Admin.Experts.PageContent";
import { AdminLedgerBalancesPageContent } from "../components/admin/Admin.LedgerBalances.PageContent";
import { AdminOrdersPageContent } from "../components/admin/Admin.Orders.PageContent";
import { AdminPaymentReleaseRequestsPageContent } from "../components/admin/Admin.PaymentReleaseRequests.PageContent";
import { AdminSkillsPageContent } from "../components/admin/Admin.Skills.PageContent";
import { AdminUsersPageContent } from "../components/admin/Admin.Users.PageContent";
import { AdminInterviewExperiencesContent } from "../components/admin/AdminInterviewExperiencesContent";
import AdminPage from "../components/admin/AdminPage";
import { AdminPaymentsContent } from "../components/admin/AdminPaymentsContent";
import { AdminQuestionsContent } from "../components/admin/AdminQuestionsContent";
import { AdminSessionsContent } from "../components/admin/AdminSessionsContent";
import AdminTestsContent from "../components/admin/AdminTestsContent";
import { AdminWithdrawalsPageContent } from "../components/admin/AdminWithdrawals.PageContent";
import { adminLinkConstants } from "../constants";

function AdminOrders() {
  return <AdminPage contentComponent={AdminOrdersPageContent} />;
}

function AdminPRRs() {
  return (
    <AdminPage contentComponent={AdminPaymentReleaseRequestsPageContent} />
  );
}

function AdminUsers() {
  return <AdminPage contentComponent={AdminUsersPageContent} />;
}

function AdminExperts() {
  return <AdminPage contentComponent={AdminExpertsPageContent} />;
}

function AdminSkills() {
  return <AdminPage contentComponent={AdminSkillsPageContent} />;
}

function AdminLedgerBalances() {
  return <AdminPage contentComponent={AdminLedgerBalancesPageContent} />;
}

function AdminPayments() {
  return <AdminPage contentComponent={AdminPaymentsContent} />;
}

function AdminWithdrawals() {
  return <AdminPage contentComponent={AdminWithdrawalsPageContent} />;
}

function AdminSessions() {
  return <AdminPage contentComponent={AdminSessionsContent} />;
}

function AdminInterviewExperiences() {
  return <AdminPage contentComponent={AdminInterviewExperiencesContent} />;
}

function AdminQuestionsPage() {
  return <AdminPage contentComponent={AdminQuestionsContent} />;
}

function AdminTests() {
  return <AdminPage contentComponent={AdminTestsContent} />;
}

export function AdminRoutes() {
  return (
    <>
      <Route
        exact
        path={adminLinkConstants.PAYMENTS}
        component={AdminPayments}
      />
      <Route
        exact
        path={adminLinkConstants.BOOKINGS}
        component={AdminSessions}
      />
      <Route
        exact
        path={adminLinkConstants.INTERVIEW_EXPERIENCES}
        component={AdminInterviewExperiences}
      />
      <Route
        exact
        path={adminLinkConstants.QUESTIONS}
        component={AdminQuestionsPage}
      />
      <Route exact path="/admin/tests" component={AdminTests} />
      <Route exact path={adminLinkConstants.ORDERS} component={AdminOrders} />
      <Route exact path={adminLinkConstants.USERS} component={AdminUsers} />
      <Route exact path={adminLinkConstants.EXPERTS} component={AdminExperts} />

      <Route exact path={adminLinkConstants.PRRS} component={AdminPRRs} />
      <Route
        exact
        path={adminLinkConstants.LEDGER_BALANCES}
        component={AdminLedgerBalances}
      />
      <Route
        exact
        path={adminLinkConstants.WITHDRAWALS}
        component={AdminWithdrawals}
      />

      <Route exact path={adminLinkConstants.SKILLS} component={AdminSkills} />
    </>
  );
}
