import React, { useState } from "react";
import { useAlert } from "react-alert";
import { SearchAPI } from "../../api/SearchAPI";
import { ApiErrorHelper } from "../../helpers/ApiError.helper";
import { User, UserBasicDetails } from "../../user/User";
import { CallbackButton } from "./ApiLinkedButton";
import { CallerContext } from "./CallerContext";

interface SearchUserViewProps {
  RowItemComponent: React.ComponentType<{ user: User }>;
  callerContext: CallerContext;
  user?: UserBasicDetails;
}

export function SearchUserView(props: SearchUserViewProps) {
  const [users, setUsers] = useState<User[] | undefined>(
    props.user && [{ basicDetails: props.user }]
  );
  const [searchValue, setSearchValue] = useState<string>();

  const alert = useAlert();

  function onSearch(): Promise<void> {
    if (!searchValue || searchValue.trim().length === 0) {
      alert.error("Search value is required.");
      return Promise.resolve();
    }
    return SearchAPI.searchUser({ email: searchValue, userName: searchValue })
      .then(function (users: User[]) {
        setUsers(users);
        return Promise.resolve();
      })
      .catch(function (error) {
        let displayErrorMessage = ApiErrorHelper.getDisplayErrorMessage(error);
        alert.error(displayErrorMessage);
        return Promise.reject(error);
      });
  }

  const onSearchValueChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  return (
    <div>
      <blockquote className="text-muted">
        Username is something which is present in the user profile url at the
        end. For ex: if user's profile url is: enginebogie.com/u/anomaly2104,
        then the username is <mark>anomaly2104</mark>
      </blockquote>
      <input
        required
        className="form-control mb-2"
        placeholder="Enter the username of the user you want to search here."
        onChange={onSearchValueChange}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            onSearch();
          }
        }}
      />

      <input type="submit" hidden />
      <CallbackButton
        buttonProps={{ text: { normal: "Search User" } }}
        callback={onSearch}
        uniqueKey={""}
      />

      <div className="row mt-4 pt-2">
        <div className="col">
          {users && (
            <>
              {users.length > 0 ? (
                <UserListView users={users} {...props} />
              ) : (
                <div>No users found</div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

interface UserListViewProps {
  users: User[];
  callerContext: CallerContext;
  RowItemComponent: React.ComponentType<{ user: User }>;
}

class UserListView extends React.Component<UserListViewProps, {}> {
  render() {
    return (
      <>
        <div className="accordion" id="accordionQuestions">
          {this.props.users.map((user, index) => {
            return (
              <div key={index}>
                <this.props.RowItemComponent user={user} />
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
function resolve(user: User) {
  throw new Error("Function not implemented.");
}
