import { Formik } from "formik";
import React, { useState } from "react";
import { CallerContext } from "../components/common/CallerContext";
import { EBTextInput } from "../components/common/eb-ui-components";
import { SearchTokenHelper } from "../helpers/Search.helper";
import { PageResponse } from "../paging/Paging.Model";
import { DifficultyBadge } from "./Difficulty.badge";
import { Question } from "./Question.Model";
import { QuestionBagdesView, QuestionContentView } from "./Question.view";
import { QuestionsAPI } from "./QuestionsAPI";

interface SearchQuestionViewProps {
  callback: (question: Question, callerContext: CallerContext) => void;
  callerContext: CallerContext;
}

export function SearchQuestionView(props: SearchQuestionViewProps) {
  const initialState = (): Question[] => [];
  const [questions, setQuestions] = useState(initialState);

  return (
    <div>
      <Formik
        initialValues={{ searchQuery: "" }}
        onSubmit={(values) => {
          return QuestionsAPI.search()
            .executeV2({
              searchTokens: [
                SearchTokenHelper.getSearchStringToken(values.searchQuery),
              ],
              pageParams: { page: 0, size: 1000 }, //TODO: Hack to fetch all the matching questions for now. We need to move to pagination here.
            })
            .then(function (response: PageResponse<Question>) {
              setQuestions(response.content);
            });
        }}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <div className="row">
              <div className="col">
                <EBTextInput
                  label="Enter some search text. We will return questions contaning that text:"
                  name="searchQuery"
                  required
                  placeholder="Enter Search Query Here"
                  helpText=""
                />
                <button
                  className="btn btn-outline-success my-2 my-sm-0"
                  type="submit"
                >
                  Search
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div className="row pt-2">
        <div className="col">
          <QuestionsListView
            questions={questions}
            callback={props.callback}
            callerContext={props.callerContext}
          />
        </div>
      </div>
    </div>
  );
}

interface QuestionsListViewProps {
  questions: Question[];
  callback: (questions: Question, callerContext: CallerContext) => void;
  callerContext: CallerContext;
}

class QuestionsListView extends React.Component<QuestionsListViewProps, {}> {
  render() {
    return (
      <>
        <div className="accordion" id="accordionQuestions">
          {this.props.questions.map((question, index) => {
            let quesStrId = "ques" + question.id;
            let headingStrId = "heading" + question.id;
            return (
              <div className="accordion-item" key={quesStrId}>
                <div className="accordion-header d-flex" id={headingStrId}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#" + quesStrId}
                    aria-expanded="false"
                    aria-controls={quesStrId}
                  >
                    <div className="flex-grow-1">
                      {question.title}{" "}
                      <div className="mt-2">
                        <QuestionBagdesView question={question} />
                      </div>
                    </div>

                    <div className="justify-content-end pl-4 pr-2">
                      <DifficultyBadge difficulty={question.difficulty} />
                    </div>

                    <div className="justify-content-end pr-2">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={(event) => {
                          this.props.callback(
                            question,
                            this.props.callerContext
                          );
                          event.preventDefault();
                          event.stopPropagation();
                          return false;
                        }}
                      >
                        Use
                      </button>
                    </div>
                  </button>
                </div>

                <div
                  id={quesStrId}
                  className="accordion-collapse collapse"
                  aria-labelledby={headingStrId}
                  data-bs-parent="#accordionQuestions"
                >
                  <div className="accordion-body">
                    <div className="">
                      <div className="pl-3">
                        <QuestionContentView question={question} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}
