import React from "react";
import { ButtonText } from "../../model/views/Button.props";
import { User, UserBasicDetails } from "../../user/User";
import { UserLink } from "../../user/User.link";
import { CallbackButton } from "./ApiLinkedButton";
import { UserPickerView } from "./UserPickerView";

interface UserPickerViewDefaultDesignProps {
  uniqueKey: string;
  callback: (user: User) => Promise<void>;

  initiatorButtonText?: ButtonText;
  userPickerButtonText?: ButtonText;
  user: UserBasicDetails;
}

export function UserPickerViewDefaultDesign(
  mainProps: UserPickerViewDefaultDesignProps
) {
  function UserRow(props: { user: User }) {
    let userPickerButtonText: ButtonText = {
      normal: "Pick",
      submitting: "Saving...",
    };
    if (mainProps.userPickerButtonText) {
      userPickerButtonText = mainProps.userPickerButtonText;
    }
    return (
      <li className="list-group-item ">
        <div className="row">
          <div className="col">
            <UserLink user={props.user.basicDetails} />
          </div>
          <div className="col-auto">
            <CallbackButton
              buttonProps={{
                text: userPickerButtonText,
                extraClassNames: "btn-sm",
              }}
              callback={function (): Promise<void> {
                document.getElementById(modalCloseButtonId)?.click();
                return mainProps.callback(props.user);
              }}
              uniqueKey={""}
            />
          </div>
        </div>
      </li>
    );
  }

  let modalCloseButtonId =
    mainProps.uniqueKey + "-user-picker-modal-close-button";

  return (
    <UserPickerView
      RowItemComponent={UserRow}
      uniqueKey={mainProps.uniqueKey}
      modalCloseButtonId={modalCloseButtonId}
      pickerButtonContent={mainProps.initiatorButtonText}
      user={mainProps.user}
    />
  );
}
