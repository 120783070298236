import { FieldArray, useField } from "formik";
import React from "react";
import { AddTitle } from "../components/common/Add.title";
import { UserInput } from "../components/common/input/User.Input";
import { UserBasicDetails } from "../user/User";

export interface EventCreateOrUpdateDetailsInstructorsStepProps {
  user: UserBasicDetails;
}

export function EventCreateOrUpdateDetailsInstructorsStep(
  props: EventCreateOrUpdateDetailsInstructorsStepProps
) {
  let [field] = useField("instructors");
  let instructors: UserBasicDetails[] = field.value;
  return (
    <div className="EventCreateOrUpdateDetailsInstructorsStep">
      <p>Please add instructors who will be handling this event!!</p>

      <FieldArray name={`instructors`}>
        {(arrayHelpers: any) => (
          <div key={arrayHelpers.index}>
            {instructors &&
              instructors.map((instructor, index) => (
                <div className="hstack gap-2 mt-2">
                  <UserInput
                    name={`instructors.${index}`}
                    user={props.user}
                    key={index}
                    selectedUsers={instructors}
                  />
                  <button
                    className="btn text-danger btn-sm"
                    type="button"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <i className="far fa-trash-alt"></i> Delete
                  </button>
                </div>
              ))}

            <div className="row ">
              <div className="col-lg-9 col-sm-12 float-right">
                <div className="row">
                  <div className="col-auto p-0">
                    <button
                      className="btn text-secondary mt-2 "
                      type="button"
                      onClick={() => {
                        arrayHelpers.push({});
                      }}
                    >
                      <AddTitle text={"Add Instructor"} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </FieldArray>
    </div>
  );
}
