import { Col, Row } from "antd";
import React from "react";
import { QuestionsFilterView } from "../components/common/filters/QuestionsFilterView";
import { RoundedPillButton } from "../components/pages/charchaa/RoundedPillButton";
import { publicLinkConstants } from "../constants";
import { WindowHelpers } from "../helpers/Window.helper";
import {
  ListItemLayoutCard,
  ListItemLayoutDefault,
} from "../list/ListItem.Layout";
import { ListItemProps, ListV2 } from "../list/ListV2";
import { SearchRequest } from "../model/search/UserSearchRequestV1";
import { SortDirection } from "../model/sort/SortToken";
import { SortTokenName } from "../model/sort/SortTokenName";
import { PageResponse } from "../paging/Paging.Model";
import { RelatedExpertsComponent } from "../promotion/RelatedExperts.Component";
import { User } from "../user/User";
import { Question } from "./Question.Model";
import { QuestionsAPI } from "./QuestionsAPI";
import { QuestionsListItem } from "./QuestionsList";

interface ListQuestionsPageContentProps {
  user?: User;
}
export function ListQuestionsPageContent(props: ListQuestionsPageContentProps) {
  function onFetch(
    searchRequest: SearchRequest
  ): Promise<PageResponse<Question>> {
    return QuestionsAPI.search().executeV2(searchRequest);
  }

  return (
    <div className="main-container bg-main-body h-100">
      <Row>
        <Col className="mr-5">
          <h1 className="h3">All Problems</h1>
        </Col>
        <Col flex={"auto"}>
          <RoundedPillButton
            redirectUrl={publicLinkConstants.QUESTION_ADD}
            title={
              WindowHelpers.isMobile()
                ? "Add problem"
                : "Have a problem to add? Contribute now"
            }
          ></RoundedPillButton>
        </Col>
      </Row>

      <Row gutter={[15, 10]} className="mt-3">
        <Col xs={24} sm={12} md={14} lg={16} xxl={18}>
          <ListV2
            paginationPosition="bottom"
            user={props.user?.basicDetails}
            SearchComponent={QuestionsFilterView}
            gutter={[10, 10]}
            onFetch={(searchRequest) => {
              return onFetch(searchRequest ?? {});
            }}
            itemNode={(itemProps: ListItemProps) => {
              let question = itemProps.item as Question;
              return (
                <QuestionsListItem
                  question={question}
                  dl={<span className="h5">{question.title}</span>}
                />
              );
            }}
            emptyListContent="No questions found"
            ItemLayoutComponent={ListItemLayoutCard}
          />
        </Col>

        <Col xs={24} sm={12} md={10} lg={8} xxl={6}>
          <div className="card rounded-5 p-0 overflow-hidden py-3">
            <div className="ml-3 bold text-muted">
              <span> Popular Questions</span>
            </div>
            <ListV2
              hidePaging={true}
              onFetch={() => {
                return onFetch({
                  sortTokens: [
                    { name: SortTokenName.VIEW, direction: SortDirection.DESC },
                  ],
                  pageParams: { page: 0, size: 5 },
                });
              }}
              itemNode={(itemProps: ListItemProps) => {
                let question = itemProps.item as Question;
                return (
                  <QuestionsListItem
                    dl={
                      <span className="font-weight-bold">{question.title}</span>
                    }
                    question={question}
                    hideEdit={true}
                    hideReactionText={true}
                    hideTopics={true}
                  />
                );
              }}
              emptyListContent="No questions found"
              ItemLayoutComponent={ListItemLayoutDefault}
            />
          </div>
          <RelatedExpertsComponent context={{ topics: [] }} className="mt-3" />
        </Col>
      </Row>
    </div>
  );
}
