import axios from "axios";
import { Api, ApiMethod } from "../components/common/ApiLinkedButton";
import { apiLinkConstants } from "../constants";
import { Company } from "../model/Company";

export class CompanyAPI {
  public static async fetchAllCompanies(): Promise<Company[]> {
    return new Promise<Company[]>((resolve, reject) => {
      axios
        .get("/api/company/all")
        .then((res) => {
          const responseData = res.data;
          resolve(responseData);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public static addCompany(company: Company): Promise<Company> {
    return new Api<Company, Company>(
      apiLinkConstants.API_COMPANY_ADD,
      ApiMethod.POST
    ).execute(company);
  }
}
