import React from "react";
import { EBButton } from "../components/common/EBButton";
import { UserBasicDetails } from "../user/User";
import { IContentItemHandler } from "./ContentItemType.Handlers";

interface ContentInputSidebartProps {
  user: UserBasicDetails;
  contentItemTypeHandlers: IContentItemHandler[];
  onSelect: (itemTypeHandler: IContentItemHandler) => void;
}

export function ContentInputSidebar(props: ContentInputSidebartProps) {
  return (
    <div className="ContentInputSidebar">
      {props.contentItemTypeHandlers.map(
        (itemTypeHandler: IContentItemHandler, index: number) => (
          <div key={index} className="">
            <EBButton
              classes={[
                "btn btn-light  w-100 border border-2 rounded-0 fs-5 text-start pl-3 py-3",
              ]}
              onClick={() => {
                props.onSelect(itemTypeHandler);
              }}
              text={{ normal: itemTypeHandler.sidebarListView }}
            />
          </div>
        )
      )}
    </div>
  );
}
