import React from "react";

class ContentComponent extends React.Component {
  render() {
    return (
      <div className="main-container h-100 bg-main-body">
        <div class="card text-dark bg-white mb-3">
          <div class="card-body">
            <h5 class="card-title">Thanks for using this platform. </h5>

            <p class="card-text">
              For any queries or help, please drop us a mail at:{" "}
              <span class="font-weight-bold text-primary">
                {process.env.REACT_APP_CONTACT_EMAIL}
              </span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentComponent;
