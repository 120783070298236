import React from "react";
import { Route } from "react-router";
import { EngineName } from "./Global.Names";
import { BookingPathRoutes } from "./bookings-path/BookingPath.routes";
import { SessionDetailsPageContent } from "./components/common/expert-session/SessionDetailsContent";
import { CancellationRefundsView } from "./components/pages/CancellationRefundsView";
import HelpContent from "./components/pages/HelpContent";
import { IndependenceDayEvent } from "./components/pages/IndependenceDayEvent";
import { IntervieweRoundInterviewerPageContent } from "./components/pages/IntervieweRoundInterviewerPageContent";
import NormalNavBar from "./components/pages/NormalNavBar.tsx";
import { OrderDetailsPageContent } from "./components/pages/OrderDetailsPageContent";
import { PrivacyPolicyContent } from "./components/pages/PrivacyPolicyContent";
import PublicPage from "./components/pages/PublicPage";
import { TermsConditions } from "./components/pages/TermsConditions";
import { CompanyAllInterviewRoundsPage } from "./components/pages/ats/CompanyAllInterviewRoundsPage";
import { InterviewLoopDetailsPage } from "./components/pages/ats/InterviewLoopDetailsPage";
import { InterviewLoopsPage } from "./components/pages/ats/InterviewLoopsPage";
import { InterviewRoundInterviewerFeedbackPage } from "./components/pages/ats/InterviewRoundInterviewerFeedbackPage";
import { publicLinkConstants } from "./constants";
import { ExpertStudioConstants } from "./expert-studio/ExpertStudio.constants";
import { EBLoginRoutes } from "./login/EBLogin.Routes";
import { QuestionRoutes } from "./question/Question.Routes";
import { AdminRoutes } from "./routes/Admin.routes";
import { CharchaaRoutes } from "./routes/Charchaa.routes";
import { DigitalProductRoutes } from "./routes/DigitalProduct.routes";
import { EventRoutes } from "./routes/Event.routes";
import { ExpertStudioRoutes } from "./routes/ExpertStudio.routes";
import { GeneralRoutes } from "./routes/General.routes";
import { InterviewExperienceRoutes } from "./routes/InterviewExperience.routes";
import { OfferingRoutes } from "./routes/Offering.routes";
import { StaticRoutes } from "./routes/Static.Routes";
import { TextQueryRoutes } from "./routes/TextQuery.routes";
import { UserRoutes } from "./routes/User.routes";

function Help(props) {
  return (
    <PublicPage
      contentComponent={HelpContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function OrderDetails(props) {
  return (
    <PublicPage
      contentComponent={OrderDetailsPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function PrivacyPolicy(props) {
  return (
    <PublicPage
      contentComponent={PrivacyPolicyContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}
function TermsConditionsPage(props) {
  return (
    <PublicPage
      contentComponent={TermsConditions}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function CancellatonRefundsPage(props) {
  return (
    <PublicPage
      contentComponent={CancellationRefundsView}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function IndependenceDayOffer(props) {
  return (
    <PublicPage
      contentComponent={IndependenceDayEvent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function SessionDetails(props) {
  return (
    <PublicPage
      contentComponent={SessionDetailsPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
    />
  );
}

function InterviewLoops(props) {
  return (
    <PublicPage
      contentComponent={InterviewLoopsPage}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
      companyId={props.match.params.companyId}
    />
  );
}

function InterviewLoopDetails(props) {
  return (
    <PublicPage
      contentComponent={InterviewLoopDetailsPage}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
      companyId={props.match.params.companyId}
      interviewLoopId={props.match.params.interviewLoopId}
    />
  );
}

function InterviewRoundInterviewerPage(props) {
  return (
    <PublicPage
      contentComponent={IntervieweRoundInterviewerPageContent}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
      interviewRoundId={props.match.params.interviewRoundId}
    />
  );
}

function InterviewRoundInterviewerFeedbackPageWrapper(props) {
  return (
    <PublicPage
      contentComponent={InterviewRoundInterviewerFeedbackPage}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
      interviewRoundId={props.match.params.interviewRoundId}
    />
  );
}

function CompanyAllInterviewRoundsPageWrapper(props) {
  return (
    <PublicPage
      contentComponent={CompanyAllInterviewRoundsPage}
      navBarComponent={NormalNavBar}
      {...props}
      headerLinks={navbarLinks()}
      companyId={props.match.params.companyId}
    />
  );
}

export function navbarLinks() {
  return [
    {
      id: "expertsessions",
      text: "Expert Studio",
      href: ExpertStudioConstants.PAGE_EXPERT_STUDIO_BOOKINGS_GROUP_BY_BOGIE,
      faIconClass: "fas fa-toolbox",
    },
    {
      id: "findexperts",
      text: "Find " + EngineName.SENTENCE_START_PLURAL,
      href: publicLinkConstants.FIND_EXPERTS,
      faIconClass: "fas fa-search",
    },
    {
      id: "interview-experiences",
      text: "Interview Experiences",
      href: publicLinkConstants.INTERVIEW_EXPERIENCES,
      faIconClass: "fas fa-clipboard-list",
    },
    {
      id: "questions",
      text: "Problems",
      href: publicLinkConstants.QUESTIONS,
      faIconClass: "fas fa-clipboard-list",
    },
    {
      id: "charchaa",
      text: "Charchaa",
      href: publicLinkConstants.CHARCHAA,
      faIconClass: "fa-solid fa-people-group",
    },
    {
      id: "help",
      text: "Help",
      href: publicLinkConstants.HELP_LINK,
      faIconClass: "fa fa-circle-info",
    },
  ];
}

function App() {
  return (
    <>
      <StaticRoutes />
      <Route
        exact
        path={publicLinkConstants.SESSION_DETAILS_LINK}
        component={SessionDetails}
      />
      <Route exact path={publicLinkConstants.HELP_LINK} component={Help} />
      <Route
        exact
        path={publicLinkConstants.PRIVACY_POLICY_LINK}
        component={PrivacyPolicy}
      />
      <Route
        exact
        path={publicLinkConstants.TERMS_CONDITIONS_LINK}
        component={TermsConditionsPage}
      />
      <Route
        exact
        path={publicLinkConstants.CANCELLATION_REFUNDS_LINK}
        component={CancellatonRefundsPage}
      />

      <Route
        exact
        path={publicLinkConstants.INDEPENDENCE_DAY_OFFER}
        component={IndependenceDayOffer}
      />

      <Route
        exact
        path={publicLinkConstants.ORDER_DETAILS}
        component={OrderDetails}
      />

      <Route
        exact
        path={publicLinkConstants.INTERVIEW_LOOPS_PAGE}
        component={InterviewLoops}
      />
      <Route
        exact
        path={publicLinkConstants.SPECIFIC_INTERVIEW_LOOP_PAGE}
        component={InterviewLoopDetails}
      />
      <Route
        exact
        path={publicLinkConstants.INTERVIEW_ROUND_INTERVIEWER_PAGE}
        component={InterviewRoundInterviewerPage}
      />
      <Route
        exact
        path={publicLinkConstants.COMPANY_INTERVIEW_ROUNDS_ALL_PAGE}
        component={CompanyAllInterviewRoundsPageWrapper}
      />
      <Route
        exact
        path={publicLinkConstants.INTERVIEW_ROUND_INTERVIEWER_FEEDBACK_PAGE}
        component={InterviewRoundInterviewerFeedbackPageWrapper}
      />

      <AdminRoutes />
      <UserRoutes />
      <ExpertStudioRoutes />
      <TextQueryRoutes />
      <GeneralRoutes />
      <OfferingRoutes />
      <DigitalProductRoutes />
      <BookingPathRoutes />
      <CharchaaRoutes />
      <InterviewExperienceRoutes />
      <EventRoutes />
      <QuestionRoutes />
      <EBLoginRoutes />
    </>
  );
}

export default App;
